<template>
  <div>
    <p v-html="successTemplate"></p>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    successData() {
      return this.$store.state.visitor.successData
    },
    successTemplate() {
      // Check if successData is available
      if (!this.successData) return ''

      // Destructure the required properties from successData
      const { full_name, phone } = this.successData
      return `
        <!DOCTYPE html>
<html lang="en" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">

<head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">

  <meta name="x-apple-disable-message-reformatting">
  <meta http-equiv="x-ua-compatible" content="ie=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <meta name="format-detection" content="telephone=no, date=no, address=no, email=no">
  <!--[if mso]>
    <xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml>
    <style>
      td,th,div,p,a,h1,h2,h3,h4,h5,h6 {font-family: "Segoe UI", sans-serif; mso-line-height-rule: exactly;}
    </style>
  <![endif]-->
  <title>Verify Email Address</title>
  <link href="./Verify Email Address_files/css" rel="stylesheet" media="screen">
  <style>
    .hover-underline:hover {
      text-decoration: underline !important;
    }

    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }

    @keyframes ping {

      75%,
      100% {
        transform: scale(2);
        opacity: 0;
      }
    }

    @keyframes pulse {
      50% {
        opacity: .5;
      }
    }

    @keyframes bounce {

      0%,
      100% {
        transform: translateY(-25%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
      }

      50% {
        transform: none;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
      }
    }

    @media (max-width: 600px) {
      .sm-leading-32 {
        line-height: 32px !important;
      }

      .sm-px-24 {
        padding-left: 24px !important;
        padding-right: 24px !important;
      }

      .sm-py-32 {
        padding-top: 32px !important;
        padding-bottom: 32px !important;
      }

      .sm-w-full {
        width: 100% !important;
      }
    }
  </style>
  <style>
    .clockify-integration-popup {
      background: #eff4f7;
      box-shadow: rgba(0, 0, 0, .12) 0px 1px 3px, rgba(0, 0, 0, .24) 0px 1px 2px;
      width: 350px;
      position: fixed;
      top: 3vh;
      right: 3vw;
      max-height: 90vh;
      overflow-y: auto;
      z-index: 2147483647 !important;
      color: #333;
      font-size: 14px
    }

    .clockify-integration-popup *::selection {
      background: highlight;
      color: highlighttext
    }

    .clockify-integration-popup img,
    .clockify-integration-popup svg {
      vertical-align: inherit
    }

    .clockify-integration-popup input:not([type=image i]) {
      box-sizing: border-box
    }

    .clockify-integration-popup input,
    .clockify-integration-popup textarea {
      box-shadow: none
    }

    .clockify-integration-popup label {
      display: inline-block !important;
      font-weight: normal
    }

    .clockify-integration-popup span {
      float: none
    }

    .clockify-integration-popup-close-icon {
      width: 18px;
      height: 18px;
      cursor: pointer;
      position: absolute;
      top: 16px;
      right: 20px
    }

    .clockify-integration-popup-header {
      height: 20px;
      width: fit-content
    }

    .clockify-integration-popup .toaster__container {
      position: absolute !important
    }

    .clockify-integration-popup .projects-list {
      display: block
    }

    .clockify-integration-popup .ant-switch {
      min-height: auto
    }

    #clockifyButton,
    #clockifySmallButton {
      font-size: 14px;
      cursor: pointer
    }

    #clockifyButton img,
    #clockifyButton svg,
    #clockifySmallButton img,
    #clockifySmallButton svg {
      vertical-align: inherit
    }

    #clockifyButton {
      display: flex !important;
      align-items: center !important
    }

    #clockifyButton>div {
      display: flex !important;
      align-items: center !important;
      justify-content: center
    }

    #clockifyButton>div>span {
      margin-left: 5px
    }

    .clockify-button-active {
      color: #03a9f4
    }

    .clockify-button-inactive {
      color: #444
    }

    .clockify-button-active-span,
    .clockify-button-inactive-span {
      margin-left: 5px;
      float: none;
      position: relative
    }

    .clockify-input {
      border: 1px solid #c6d2d9;
      border-radius: 2px;
      width: 136px;
      padding-left: 4px;
      height: 30px;
      font-size: 14px
    }

    .clockify-input:focus,
    .clockify-input:hover {
      outline: none;
      box-shadow: 0 0 4px rgba(0, 0, 0, .05);
      border: 1px solid #03a9f4;
      background-color: #fff
    }

    .clockify-input.clockify-input-default {
      border-color: #c6d2d9;
      color: #333;
      background-color: #fff
    }

    .clockify-input.clockify-input-success {
      border-color: #cde9ce;
      color: #285b2a;
      background-color: #dbefdc
    }

    .clockify-input.clockify-input-error {
      border-color: #fccac7;
      color: #7f231c;
      background-color: #fdd9d7
    }

    .clockify-manual-entry-header-text {
      margin-left: 20px
    }

    .clockify-trello-card .clockifyButton {
      visibility: hidden
    }

    .clockify-trello-card:hover .clockifyButton {
      visibility: visible
    }

    .clockify-trello-card:not(:hover) .clockifyButton {
      visibility: hidden
    }

    .clockify-trello-card:not(:hover) .clockifyButton.active {
      visibility: visible
    }

    .clockify-trello-card:not(:hover) .clockifyButton:not(.active) {
      visibility: hidden
    }

    .clockify-copy-as-entry-container {
      display: flex;
      align-items: center;
      gap: 3px;
      cursor: pointer;
      width: fit-content;
      color: #444
    }

    .clockify-integration-popup {
      @import'~@fontsource/roboto/400.css';
      @import'~@fontsource/roboto/700.css'
    }

    .clockify-integration-popup .ant-switch {
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5715;
      list-style: none;
      font-feature-settings: "tnum";
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      min-width: 44px;
      height: 22px;
      line-height: 22px;
      vertical-align: middle;
      background-color: rgba(0, 0, 0, 0.25);
      border: 0;
      border-radius: 100px;
      cursor: pointer;
      transition: all .2s;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none
    }

    .clockify-integration-popup .ant-switch:focus {
      outline: 0;
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1)
    }

    .clockify-integration-popup .ant-switch-checked:focus {
      box-shadow: 0 0 0 2px #e6f7ff
    }

    .clockify-integration-popup .ant-switch:focus:hover {
      box-shadow: none
    }

    .clockify-integration-popup .ant-switch-checked {
      background-color: #1890ff
    }

    .clockify-integration-popup .ant-switch-loading,
    .clockify-integration-popup .ant-switch-disabled {
      cursor: not-allowed;
      opacity: .4
    }

    .clockify-integration-popup .ant-switch-loading *,
    .clockify-integration-popup .ant-switch-disabled * {
      box-shadow: none;
      cursor: not-allowed
    }

    .clockify-integration-popup .ant-switch-inner {
      display: block;
      margin: 0 7px 0 25px;
      color: #fff;
      font-size: 12px;
      transition: margin .2s
    }

    .clockify-integration-popup .ant-switch-checked .ant-switch-inner {
      margin: 0 25px 0 7px
    }

    .clockify-integration-popup .ant-switch-handle {
      position: absolute;
      top: 2px;
      left: 2px;
      width: 18px;
      height: 18px;
      transition: all .2s ease-in-out
    }

    .clockify-integration-popup .ant-switch-handle::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #fff;
      border-radius: 9px;
      box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
      transition: all .2s ease-in-out;
      content: ""
    }

    .clockify-integration-popup .ant-switch-checked .ant-switch-handle {
      left: calc(100% - 18px - 2px)
    }

    .clockify-integration-popup .ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
      right: -30%;
      left: 0
    }

    .clockify-integration-popup .ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
      right: 0;
      left: -30%
    }

    .clockify-integration-popup .ant-switch-loading-icon.anticon {
      position: relative;
      top: 2px;
      color: rgba(0, 0, 0, 0.65);
      vertical-align: top
    }

    .clockify-integration-popup .ant-switch-checked .ant-switch-loading-icon {
      color: #1890ff
    }

    .clockify-integration-popup .ant-switch-small {
      min-width: 28px;
      height: 16px;
      line-height: 16px
    }

    .clockify-integration-popup .ant-switch-small .ant-switch-inner {
      margin: 0 5px 0 18px;
      font-size: 12px
    }

    .clockify-integration-popup .ant-switch-small .ant-switch-handle {
      width: 12px;
      height: 12px
    }

    .clockify-integration-popup .ant-switch-small .ant-switch-loading-icon {
      top: 1.5px;
      font-size: 9px
    }

    .clockify-integration-popup .ant-switch-small.ant-switch-checked .ant-switch-inner {
      margin: 0 18px 0 5px
    }

    .clockify-integration-popup .ant-switch-small.ant-switch-checked .ant-switch-handle {
      left: calc(100% - 12px - 2px)
    }

    .clockify-integration-popup .ant-switch-rtl {
      direction: rtl
    }

    .clockify-integration-popup .ant-switch-rtl .ant-switch-inner {
      margin: 0 25px 0 7px
    }

    .clockify-integration-popup .ant-switch-rtl .ant-switch-handle {
      right: 2px;
      left: auto
    }

    .clockify-integration-popup .ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before {
      right: 0;
      left: -30%
    }

    .clockify-integration-popup .ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
      right: -30%;
      left: 0
    }

    .clockify-integration-popup .ant-switch-rtl.ant-switch-checked .ant-switch-inner {
      margin: 0 7px 0 25px
    }

    .clockify-integration-popup .ant-switch-rtl.ant-switch-checked .ant-switch-handle {
      right: calc(100% - 18px - 2px)
    }

    .clockify-integration-popup .ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
      right: calc(100% - 12px - 2px)
    }

    .clockify-integration-popup .start-timer {
      position: relative;
      display: flex;
      justify-content: space-between;
      height: 50px;
      margin: 10px 20px;
      background: #fff;
      border: 1px solid #c6d2d9;
      border-radius: 2px
    }

    .clockify-integration-popup .start-timer_button-red {
      width: 110px;
      height: 40px;
      margin: 5px;
      border: none;
      border-radius: 2px;
      background: #f44336;
      color: #eff4f7;
      cursor: pointer
    }

    .clockify-integration-popup .start-timer_button-red:hover .button_timer {
      display: none
    }

    .clockify-integration-popup .start-timer_button-red:hover .button_stop {
      display: block
    }

    .clockify-integration-popup .button_stop {
      display: none
    }

    .clockify-integration-popup .start-timer_button-start {
      width: 110px;
      height: 40px;
      margin: 5px;
      border: none;
      border-radius: 2px;
      background: #03a9f4;
      color: #eff4f7;
      cursor: pointer
    }

    .clockify-integration-popup .start-timer_button-start:hover {
      background: #0091ea
    }

    .clockify-integration-popup .start-timer_description-input {
      height: 95%;
      width: 100%;
      padding-left: 10px;
      border: 0;
      outline: none
    }

    .clockify-integration-popup .start-timer_description-input::-ms-clear {
      display: none
    }

    .clockify-integration-popup .start-timer_placeholder {
      position: absolute;
      pointer-events: none;
      top: 0;
      bottom: 0;
      height: fit-content;
      font-size: 14px;
      left: 10px;
      margin: auto;
      color: #777
    }

    .clockify-integration-popup .start-timer_description {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      font-size: 14px;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      width: 57%;
      text-overflow: ellipsis
    }

    .clockify-integration-popup .start-timer_description_grey {
      position: absolute;
      width: 47%;
      margin-top: 17px;
      margin-bottom: 17px;
      margin-left: 17px;
      font-size: 14px;
      color: #999;
      cursor: pointer
    }

    .clockify-integration-popup .start-timer-description {
      flex: 1
    }

    .clockify-integration-popup .start-timer-description .react-autocomplete {
      height: 100%;
      width: 100%
    }

    .clockify-integration-popup .react-autocomplete {
      display: inline-block;
      position: relative;
      width: 100%
    }

    .clockify-integration-popup .react-autocomplete-menu {
      box-sizing: border-box;
      position: absolute;
      max-height: 250px;
      overflow-y: auto;
      overflow-x: hidden;
      background: #fff;
      box-shadow: 0 5px 10px rgba(0, 0, 0, .1019607843);
      z-index: 25
    }

    .clockify-integration-popup .autocomplete-dropdown-item {
      box-sizing: border-box;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      row-gap: 5px;
      width: 314px;
      font-size: 14px;
      padding: 10px;
      cursor: pointer;
      background-color: #fff
    }

    .clockify-integration-popup .autocomplete-dropdown-item:hover {
      background-color: #eee
    }

    .clockify-integration-popup .autocomplete-dropdown-item .dot {
      margin-right: 5px;
      margin-left: 5px;
      align-self: center
    }

    .clockify-integration-popup .autocomplete-dropdown-item__tag {
      color: #02567e;
      background: #e1f5fe;
      padding: 2px 8px;
      margin-left: 5px;
      max-width: 136px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis
    }

    .clockify-integration-popup .autocomplete-dropdown-item__tag:hover {
      background: #b3e5fc
    }

    .clockify-integration-popup .autocomplete-dropdown-item__tag-dots {
      align-self: flex-end
    }

    .clockify-integration-popup .autocomplete-dropdown-item__description,
    .clockify-integration-popup .autocomplete-dropdown-item__project-task>span,
    .clockify-integration-popup .autocomplete-dropdown-item__client-name {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      min-width: 30px
    }

    .clockify-integration-popup .autocomplete-dropdown-item__project-task {
      display: flex;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      min-width: 80px
    }

    .clockify-integration-popup .autocomplete-dropdown-item__project-task>span:last-child {
      min-width: 50px
    }

    .clockify-integration-popup .autocomplete-dropdown-item__client-name {
      color: #999;
      margin-left: 5px
    }

    .clockify-integration-popup .duration {
      height: 50px;
      margin: 10px 20px;
      background: #fff;
      border: 1px solid #c6d2d9;
      border-radius: 2px
    }

    .clockify-integration-popup .duration__date-picker {
      padding: 13px 4px
    }

    .clockify-integration-popup .duration .ant-time-picker {
      width: 85px
    }

    .clockify-integration-popup .duration .ant-time-picker-input {
      margin-top: 3px;
      border: none;
      text-align: center;
      width: 100%
    }

    .clockify-integration-popup .duration .ant-time-picker-icon {
      display: none
    }

    .clockify-integration-popup .duration .ant-time-picker-clear {
      display: none
    }

    .clockify-integration-popup .duration .ant-time-picker .ant-time-picker-input::placeholder {
      font-size: 11px
    }

    .clockify-integration-popup .duration.copy-as-entry {
      background: none;
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: fit-content;
      margin: 0 0 10px 0
    }

    .clockify-integration-popup .duration.copy-as-entry input:focus {
      border: 1px solid #a9a9a9 !important;
      outline: none
    }

    .clockify-integration-popup .duration.copy-as-entry #durationTimePicker {
      font-weight: 500
    }

    .clockify-integration-popup .duration.copy-as-entry #durationTimePicker,
    .clockify-integration-popup .duration.copy-as-entry #startTimePicker,
    .clockify-integration-popup .duration.copy-as-entry #endTimePicker {
      width: 70px;
      height: 40px;
      border: 1px solid #c6d2d9;
      margin: 0
    }

    .clockify-integration-popup .duration.copy-as-entry .react-datepicker__input-container {
      cursor: pointer
    }

    .clockify-integration-popup .duration.copy-as-entry .react-datepicker__day--disabled {
      color: #ccc;
      text-decoration: line-through !important;
      cursor: not-allowed !important
    }

    .clockify-integration-popup .duration-time {
      display: flex;
      margin-left: 5px;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #999
    }

    .clockify-integration-popup .duration-start {
      padding-top: 13px;
      padding-bottom: 13px;
      position: relative;
      top: 0px;
      width: 65px;
      outline: none;
      border: none;
      color: #666;
      font-size: 14px;
      margin-left: 5px;
      text-align: center
    }

    .clockify-integration-popup .duration-end {
      display: block
    }

    .clockify-integration-popup .duration-duration {
      position: relative;
      padding: 6px 2px;
      border: none;
      width: 85px;
      font-size: 18px;
      color: #333;
      outline: none
    }

    .clockify-integration-popup .duration-dash {
      position: relative;
      top: 2px
    }

    .clockify-integration-popup .duration-label {
      margin-left: 7px;
      word-break: keep-all;
      position: relative;
      top: 1px
    }

    .clockify-integration-popup .duration-divider {
      position: relative;
      top: -2px;
      height: 50px;
      border-left: 1px dotted #cacaca;
      background-color: #fff
    }

    .clockify-integration-popup .edit-form {
      padding: 20px
    }

    .clockify-integration-popup .edit-form__project_list {
      margin-bottom: 10px
    }

    .clockify-integration-popup .edit-form__break-label {
      background: #c6d2d9;
      color: #333;
      padding: 14px 21px;
      font-size: 14px
    }

    .clockify-integration-popup .edit-form__break-label .break-icon {
      display: inline-block;
      position: relative;
      top: 2px;
      margin-right: 10px;
      width: 14px;
      height: 14px;
      background-repeat: no-repeat;
      background-size: 14px 14px
    }

    .clockify-integration-popup .edit-form-description {
      position: relative;
      width: 100%;
      height: 65px;
      border: none;
      resize: none;
      box-shadow: none;
      background: none !important;
      font-size: 14px;
      outline: none;
      color: #333
    }

    .clockify-integration-popup .edit-form-description+div {
      z-index: 20
    }

    .clockify-integration-popup .description-textarea {
      margin-bottom: 10px;
      border: 1px solid #c6d2d9;
      background: #fff;
      border-radius: 2px
    }

    .clockify-integration-popup .description-textarea:has(.react-autocomplete textarea:focus) {
      border: 1px solid #a9a9a9 !important
    }

    .clockify-integration-popup .description-textarea-required {
      margin-bottom: 10px;
      border: 1px solid #f44336;
      padding: 10px;
      background: #fff;
      border-radius: 2px
    }

    .clockify-integration-popup .description-textarea:focus-within {
      border: 1px solid #90a4ae !important;
      border-radius: 2px !important
    }

    .clockify-integration-popup .description-textarea>textarea {
      padding: 6px 6px;
      box-sizing: border-box
    }

    .clockify-integration-popup .edit-form-checkbox {
      display: inline-block;
      width: 16px;
      height: 16px;
      top: 13px;
      background: #fff;
      border: 1px solid #c6d2d9;
      border-radius: 2px;
      cursor: pointer
    }

    .clockify-integration-popup .edit-form-buttons {
      margin-top: 0
    }

    .clockify-integration-popup .edit-form-buttons__billable {
      margin: 15px 0;
      line-height: 1;
      display: flex;
      align-items: center
    }

    .clockify-integration-popup .edit-form-billable-img {
      margin-top: 2px;
      margin-left: 1px
    }

    .clockify-integration-popup .edit-form-billable-img-hidden {
      margin-top: 2px;
      margin-left: 1px;
      visibility: hidden
    }

    .clockify-integration-popup .edit-form-billable {
      top: 13px;
      margin-left: 10px;
      color: #666;
      font-size: 14px;
      cursor: pointer
    }

    .clockify-integration-popup .edit-form-right-buttons {
      margin-top: 20px
    }

    .clockify-integration-popup .edit-form-right-buttons__back_and_delete {
      margin-top: 20px;
      justify-content: space-between;
      text-align: center
    }

    .clockify-integration-popup .edit-form-right-buttons__back {
      color: #03a9f4;
      cursor: pointer
    }

    .clockify-integration-popup .edit-form-delete {
      color: red;
      cursor: pointer
    }

    .clockify-integration-popup .edit-form-done-disabled {
      text-align: center
    }

    .clockify-integration-popup .edit-form-done {
      width: 100%;
      height: 50px;
      border: none;
      border-radius: 3px;
      color: #fff;
      background: #03a9f4;
      cursor: pointer;
      text-align: center
    }

    .clockify-integration-popup .edit-form-done:hover {
      background-color: #0091ea;
      border-color: #0091ea;
      transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
    }

    .clockify-integration-popup .edit-form-done-disabled {
      width: 100%;
      height: 50px;
      border: none;
      border-radius: 3px;
      color: #fff;
      background: #999
    }

    .clockify-integration-popup .shake-heartache {
      animation: kf_shake .5s 1 linear;
      -webkit-animation: kf_shake .5s 1 linear;
      -moz-animation: kf_shake .5s 1 linear;
      -o-animation: kf_shake .5s 1 linear
    }

    .clockify-integration-popup .field-required-message {
      color: #f44336;
      font-size: 10px;
      text-align: end;
      padding: 0;
      margin: 0
    }

    @keyframes kf_shake {
      0% {
        -webkit-transform: translate(20px)
      }

      20% {
        -webkit-transform: translate(-20px)
      }

      40% {
        -webkit-transform: translate(10px)
      }

      60% {
        -webkit-transform: translate(-10px)
      }

      80% {
        -webkit-transform: translate(8px)
      }

      100% {
        -webkit-transform: translate(0px)
      }
    }

    @-webkit-keyframes kf_shake {
      0% {
        -webkit-transform: translate(0px)
      }

      20% {
        -webkit-transform: translate(0px)
      }

      40% {
        -webkit-transform: translate(10px)
      }

      60% {
        -webkit-transform: translate(-10px)
      }

      80% {
        -webkit-transform: translate(8px)
      }

      100% {
        -webkit-transform: translate(0px)
      }
    }

    @-moz-keyframes kf_shake {
      0% {
        -moz-transform: translate(20px)
      }

      20% {
        -moz-transform: translate(-20px)
      }

      40% {
        -moz-transform: translate(10px)
      }

      60% {
        -moz-transform: translate(-10px)
      }

      80% {
        -moz-transform: translate(8px)
      }

      100% {
        -moz-transform: translate(0px)
      }
    }

    @-o-keyframes kf_shake {
      0% {
        -o-transform: translate(20px)
      }

      20% {
        -o-transform: translate(-20px)
      }

      40% {
        -o-transform: translate(10px)
      }

      60% {
        -o-transform: translate(-10px)
      }

      80% {
        -o-transform: translate(8px)
      }

      100% {
        -o-origin-transform: translate(0px)
      }
    }

    .clockify-integration-popup .project-list-button {
      padding: 0 6px;
      background: #fff;
      border: 1px solid #c6d2d9;
      border-radius: 2px;
      cursor: pointer;
      align-items: center;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 38px
    }

    .clockify-integration-popup .project-list-button-required {
      padding: 0px 6px;
      background: #fff;
      border: 1px solid #f44336;
      border-radius: 2px;
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 38px
    }

    .clockify-integration-popup .project-list__spec_filter_no_task_or_project {
      margin-left: auto;
      margin-right: auto;
      width: 80%;
      text-align: center
    }

    .clockify-integration-popup .project-list-button-offline {
      width: 100%;
      height: 40px;
      background: #eff4f7;
      margin: 10px 0;
      border: 1px solid #c6d2d9;
      border-radius: 2px;
      cursor: not-allowed;
      padding: 0px 6px;
      display: flex;
      align-items: center;
      box-sizing: border-box
    }

    .clockify-integration-popup .project-list-name {
      display: inline-flex;
      width: 85%;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #333;
      text-overflow: ellipsis;
      display: inline-block
    }

    .clockify-integration-popup span.project-list-name-client {
      color: #999;
      text-overflow: ellipsis
    }

    .clockify-integration-popup .project-list-arrow {
      width: 10px;
      height: 5px;
      margin-left: auto;
      display: flex;
      align-items: center;
      padding: 0px 10px 0px 10px;
      content: url(assets/images/arrow-light-mode.png)
    }

    .clockify-integration-popup .project-list-arrow-up {
      width: 10px;
      height: 5px;
      margin-left: auto;
      display: flex;
      align-items: center;
      padding: 0px 10px 0px 10px;
      content: url(assets/images/arrow-light-mode-up.png)
    }

    .clockify-integration-popup .project-list-dropdown {
      position: absolute;
      width: 100%;
      left: 0;
      top: 39px;
      background: #fff;
      z-index: 2;
      box-shadow: 0 5px 7px #ccc;
      font-size: 14px;
      border-radius: 2px
    }

    .clockify-integration-popup .project-list-dropdown--content {
      height: 269px;
      overflow-y: auto
    }

    .clockify-integration-popup .project-list-input {
      background: #fff;
      padding: 10px
    }

    .clockify-integration-popup .project-list-input input {
      background-color: #fff !important
    }

    .clockify-integration-popup .project-list-input--border {
      position: relative
    }

    .clockify-integration-popup .project-list-filter {
      width: 100%;
      height: 40px;
      padding: 10px;
      border: 1px solid #c6d2d9;
      border-radius: 2px;
      background-color: #fff !important
    }

    .clockify-integration-popup .project-list-filter__clear {
      position: absolute;
      right: 10px;
      top: 14px;
      height: 12px;
      width: 12px;
      content: url(assets/images/x.png);
      cursor: pointer
    }

    .clockify-integration-popup .project-list-filter:focus {
      outline: none;
      border: 1px solid #90a4ae !important;
      border-radius: 3px !important
    }

    .clockify-integration-popup .tasks-arrow-down {
      margin-left: 5px
    }

    .clockify-integration-popup .tasks-arrow-right {
      position: relative;
      top: -1px;
      margin-left: 5px;
      vertical-align: middle
    }

    .clockify-integration-popup ul.project-item {
      padding: 7px 5px 8px 10px;
      cursor: pointer;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      list-style-type: none;
      border-top: 1px dotted #c6d2d9
    }

    .clockify-integration-popup ul.project-item:hover {
      background-color: #e4eaee
    }

    .clockify-integration-popup li.project-item-dot {
      flex: 0 0 5px;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      display: inline-block;
      margin-right: 5px
    }

    .clockify-integration-popup li.project-item-name {
      display: inline-block;
      flex: 1 1;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      white-space: nowrap;
      color: #333
    }

    .clockify-integration-popup li.project-item-tasks {
      display: flex;
      color: #999;
      font-size: 13px;
      flex: 0 0 90px
    }

    .clockify-integration-popup li.project-item-tasks>span {
      color: #999
    }

    .clockify-integration-popup li.project-item-tasks>span img {
      width: auto
    }

    .clockify-integration-popup li.project-item-favorite {
      flex: 0 0 20px
    }

    .clockify-integration-popup .project-item-create-task {
      color: #03a9f4;
      cursor: pointer;
      font-size: 14px;
      margin-right: 10px
    }

    .clockify-integration-popup .project-item-create-task:hover {
      text-decoration: underline
    }

    .clockify-integration-popup .task-item {
      padding: 7px 0 7px 25px;
      background-color: #f2f6f8;
      color: #333;
      font-size: 14px
    }

    .clockify-integration-popup .task-item:hover {
      background-color: #e4eaee;
      cursor: pointer
    }

    .clockify-integration-popup .task-item:first-child {
      padding-top: 10px
    }

    .clockify-integration-popup .task-item:last-child {
      padding-bottom: 10px
    }

    .clockify-integration-popup .project-list-load {
      text-align: center;
      margin-top: 15px;
      margin-bottom: 35px;
      color: #666;
      cursor: pointer
    }

    .clockify-integration-popup .project-list-client {
      margin: 10px;
      padding-top: 5px;
      font-size: 14px;
      color: #999
    }

    .clockify-integration-popup .projects-list {
      position: relative
    }

    .clockify-integration-popup .projects-list__create-project,
    .clockify-integration-popup .projects-list__create-task {
      position: absolute;
      display: flex;
      align-items: center;
      padding-top: 17px;
      padding-bottom: 17px;
      bottom: 0px;
      width: 320px;
      background: #fff;
      box-shadow: 0 -5px 10px rgba(0, 0, 0, .1);
      cursor: pointer
    }

    .clockify-integration-popup .projects-list__create-project--icon,
    .clockify-integration-popup .projects-list__create-task--icon {
      width: 19px;
      height: 19px;
      margin-right: 7px;
      margin-left: 10px
    }

    .clockify-integration-popup .projects-list__create-project--text,
    .clockify-integration-popup .projects-list__create-task--text {
      color: #03a9f4;
      font-size: 14px
    }

    .clockify-integration-popup .projects-list__bottom-padding {
      padding-bottom: 54px;
      width: 100%
    }

    .clockify-integration-popup .projects-list__create-task {
      box-sizing: border-box;
      position: relative;
      padding-left: 10px;
      width: 100%
    }

    .clockify-integration-popup .clockify-error {
      color: red;
      font-size: 14px
    }

    .clockify-integration-popup .cl-dropdown-star {
      width: 16px;
      height: 16px;
      margin: 5px 3px 0px 5px
    }

    .clockify-integration-popup .tag-list {
      box-sizing: border-box;
      position: relative;
      width: 100%;
      margin-bottom: 10px
    }

    .clockify-integration-popup .tag-list.custom-field-required {
      border: 1px solid #f44336 !important
    }

    .clockify-integration-popup .tag-list.custom-field-required>.tag-list-button {
      border: none !important
    }

    .clockify-integration-popup .tag-list-name {
      display: inline-block !important;
      padding: 0 !important;
      width: 85% !important;
      font-size: 14px !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important
    }

    .clockify-integration-popup .tag-list-button {
      padding: 0px 7px;
      background: #fff;
      border: 1px solid #c6d2d9;
      border-radius: 2px;
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 38px
    }

    .clockify-integration-popup .tag-list-button-required {
      padding: 0px 6px;
      background: #fff;
      border: 1px solid #f44336;
      border-radius: 2px;
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 38px
    }

    .clockify-integration-popup .tag-list-button-offline {
      width: 100%;
      height: 38px;
      background: disabled;
      border: 1px solid #c6d2d9;
      border-radius: 2px;
      cursor: not-allowed;
      box-sizing: border-box;
      padding: 0px 6px;
      display: flex;
      align-items: center
    }

    .clockify-integration-popup .tag-list-button-disabled {
      width: 100%;
      height: 38px;
      background: #eff4f7;
      border: 1px solid #c6d2d9;
      border-radius: 2px;
      cursor: not-allowed;
      padding: 0px 6px;
      box-sizing: border-box;
      display: flex;
      align-items: center
    }

    .clockify-integration-popup .tag-list-arrow {
      width: 10px;
      height: 5px;
      margin-left: auto;
      display: flex;
      align-items: center;
      padding: 0px 10px 0px 10px;
      content: url(assets/images/arrow-light-mode.png)
    }

    .clockify-integration-popup .tag-list-arrow-up {
      width: 10px;
      height: 5px;
      margin-left: auto;
      display: flex;
      align-items: center;
      padding: 0px 10px 0px 10px;
      content: url(assets/images/arrow-light-mode-up.png)
    }

    .clockify-integration-popup .tag-list-add {
      display: inline-block;
      padding: 5px 10px 5px 0px;
      font-size: 14px;
      color: #666
    }

    .clockify-integration-popup .tag-list-selected {
      display: inline-block;
      max-width: 260px;
      border-radius: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap
    }

    .clockify-integration-popup .tag-list-selected-item {
      display: inline-block;
      padding: 5px 2px;
      border-radius: 3px;
      font-size: 14px;
      font-weight: 400;
      color: #333
    }

    .clockify-integration-popup .tag-list-selected-item:first-child {
      padding-left: 0px !important
    }

    .clockify-integration-popup .tag-list-input {
      background: #fff !important;
      padding: 10px
    }

    .clockify-integration-popup .tag-list-input--border {
      position: relative
    }

    .clockify-integration-popup .tag-list-input .tag-list-filter {
      background-color: #fff
    }

    .clockify-integration-popup .tag-list-input .tag-list-filter:focus {
      outline: none;
      border: 1px solid #90a4ae !important;
      border-radius: 3px !important
    }

    .clockify-integration-popup .tag-list-load {
      text-align: center;
      margin-top: 15px;
      margin-bottom: 10px;
      color: #666;
      cursor: pointer
    }

    .clockify-integration-popup .tag-list-dropdown {
      position: absolute;
      width: 100%;
      top: 40px;
      background: #fff;
      box-shadow: 0 5px 7px #ccc;
      z-index: 2;
      font-size: 14px;
      border-radius: 2px
    }

    .clockify-integration-popup .tag-list-dropdown--content {
      height: 217px;
      overflow-y: auto
    }

    .clockify-integration-popup .tag-list-filter {
      width: 100%;
      height: 40px;
      padding: 10px 32px 10px 10px;
      border: 1px solid #c6d2d9;
      border-radius: 2px
    }

    .clockify-integration-popup .tag-list-filter__clear {
      position: absolute;
      right: 10px;
      top: 14px;
      height: 12px;
      width: 12px;
      content: url(assets/images/x.png);
      cursor: pointer
    }

    .clockify-integration-popup .tag-list-checked {
      margin-top: 2px;
      margin-left: 1px
    }

    .clockify-integration-popup .tag-list-checked-hidden {
      margin-top: 2px;
      margin-left: 1px;
      visibility: hidden
    }

    .clockify-integration-popup .tag-list-checkbox {
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 1px solid #c6d2d9
    }

    .clockify-integration-popup .tag-list-item {
      font-size: 14px;
      margin-left: 10px
    }

    .clockify-integration-popup .tag-list-item-row {
      color: #666;
      padding: 7px 15px;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 1
    }

    .clockify-integration-popup .tag-list-item-row:hover {
      background-color: #e4eaee
    }

    .clockify-integration-popup .tag-list-item-row label {
      color: #666
    }

    .clockify-integration-popup .tag-list--not_tags {
      margin-left: 10px
    }

    .clockify-integration-popup .tag-list__create-tag {
      position: absolute;
      display: flex;
      align-items: center;
      padding-top: 17px;
      padding-bottom: 17px;
      bottom: 0;
      width: 320px;
      background: #fff;
      box-shadow: 0 -5px 10px rgba(0, 0, 0, .1);
      cursor: pointer
    }

    .clockify-integration-popup .tag-list__create-tag--icon {
      width: 19px;
      height: 19px;
      margin-right: 7px;
      margin-left: 10px
    }

    .clockify-integration-popup .tag-list__create-tag--text {
      color: #03a9f4;
      font-size: 14px
    }

    .clockify-integration-popup .tag-list__bottom-padding {
      padding-bottom: 65px;
      width: 100%
    }

    .clockify-integration-popup .tag-list__create-form {
      position: relative;
      display: flex;
      flex-direction: column;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 320px;
      height: 240px;
      background: #fff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, .25);
      border-radius: 2px;
      text-align: center;
      align-items: center
    }

    .clockify-integration-popup .tag-list__create-form__title-and-close {
      display: flex;
      margin-bottom: 20px;
      width: 100%
    }

    .clockify-integration-popup .tag-list__create-form__close {
      position: absolute;
      right: 20px;
      top: 25px;
      height: 12px;
      width: 12px;
      content: url(assets/images/x.png);
      cursor: pointer
    }

    .clockify-integration-popup .tag-list__create-form--open {
      background-color: rgba(0, 0, 0, .8);
      position: fixed;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      z-index: 999
    }

    .clockify-integration-popup .tag-list__create-form--divider {
      width: 100%;
      height: 1px;
      border-bottom: 1px dotted #c6d2d9;
      margin-bottom: 20px
    }

    .clockify-integration-popup .tag-list__create-form--title {
      margin-top: 20px;
      margin-left: 20px;
      font-size: 18px;
      color: #666
    }

    .clockify-integration-popup .tag-list__create-form--tag-name {
      margin-bottom: 20px;
      padding-left: 10px;
      border: 1px solid #c6d2d9;
      border-radius: 2px;
      width: 280px;
      height: 40px
    }

    .clockify-integration-popup .tag-list__create-form--tag-name::placeholder {
      color: #999
    }

    .clockify-integration-popup .tag-list__create-form--tag-name:focus {
      outline: none;
      border: 2px solid #0f3beb;
      border-radius: 5px
    }

    .clockify-integration-popup .tag-list__create-form--confirmation_button {
      margin-bottom: 20px;
      padding-top: 12px;
      width: 280px;
      height: 28px;
      border-radius: 2px;
      background: #03a9f4;
      color: #fff;
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer
    }

    .clockify-integration-popup .tag-list__create-form--cancel {
      font-size: 14px;
      color: #03a9f4;
      cursor: pointer
    }

    .clockify-integration-popup .poppup-modal {
      cursor: default;
      box-sizing: border-box;
      position: relative;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 320px;
      background: #fff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, .25);
      border-radius: 2px;
      text-align: center;
      align-items: center
    }

    .clockify-integration-popup .poppup-modal__title-and-close {
      display: flex;
      margin-bottom: 20px;
      width: 100%
    }

    .clockify-integration-popup .poppup-modal__close {
      position: absolute;
      right: 20px;
      top: 25px;
      height: 12px;
      width: 12px;
      content: url(assets/images/x.png);
      cursor: pointer
    }

    .clockify-integration-popup .poppup-modal--open {
      background-color: rgba(0, 0, 0, .8);
      position: fixed;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      z-index: 2147483647 !important
    }

    .clockify-integration-popup .poppup-modal--divider {
      width: 100%;
      height: 1px;
      border-bottom: 1px dotted #c6d2d9;
      margin-bottom: 20px
    }

    .clockify-integration-popup .poppup-modal--title {
      margin-top: 20px;
      margin-left: 20px;
      font-size: 18px;
      line-height: 21px;
      color: #666
    }

    .clockify-integration-popup .poppup-modal--tag-name {
      margin-bottom: 20px;
      padding-left: 10px;
      border: 1px solid #c6d2d9;
      border-radius: 2px;
      width: 280px;
      height: 40px
    }

    .clockify-integration-popup .poppup-modal--tag-name::placeholder {
      color: #999
    }

    .clockify-integration-popup .poppup-modal--confirmation_button {
      margin-bottom: 20px;
      padding-top: 12px;
      width: 280px;
      height: 28px;
      border-radius: 2px;
      background: #03a9f4;
      color: #fff;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      cursor: pointer
    }

    .clockify-integration-popup .poppup-modal--cancel {
      font-size: 14px;
      line-height: 16px;
      color: #03a9f4;
      cursor: pointer
    }

    .clockify-integration-popup .poppup-modal--text {
      max-width: 90%;
      word-break: break-word
    }

    .clockify-integration-popup .custom-fields {
      padding: 0px !important;
      border: 0px solid #c6d2d9 !important;
      background-color: #eff4f7 !important;
      margin: 0 !important;
      width: 100%
    }

    .clockify-integration-popup .custom-fields input:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid #90a4ae !important;
      border-radius: 2px !important
    }

    .clockify-integration-popup .custom-fields input:hover {
      box-shadow: none
    }

    .clockify-integration-popup .custom-field {
      box-sizing: border-box;
      padding: 0px !important;
      border-image-width: 0 !important;
      border: 0px solid #c6d2d9 !important;
      margin: 8px 0px 0px 0px !important;
      height: 40px !important;
      overflow: visible
    }

    .clockify-integration-popup .custom-field-disabled {
      box-sizing: border-box;
      padding: 0px !important;
      border-image-width: 0 !important;
      background-color: #eff4f7 !important;
      margin: 8px 0px 0px 0px !important;
      height: 40px !important;
      display: flex;
      align-items: center
    }

    .clockify-integration-popup .custom-field-number {
      position: relative;
      font-size: 14px
    }

    .clockify-integration-popup .custom-field-number .input-stepper {
      position: absolute;
      top: 11px;
      right: 16px;
      width: 13px;
      height: 14px
    }

    .clockify-integration-popup .custom-field-number .input-stepper .input-stepper-wrapper {
      display: flex;
      justify-content: center;
      background: #f1f1f1;
      width: 16px;
      height: 8px
    }

    .clockify-integration-popup .custom-field-number .input-stepper .input-stepper-wrapper:hover {
      background: #c1c1c1
    }

    .clockify-integration-popup .custom-field-number .input-stepper .input-stepper-wrapper:active {
      background: #a1a1a1
    }

    .clockify-integration-popup .custom-field-number .input-stepper .input-stepper-up,
    .clockify-integration-popup .custom-field-number .input-stepper .input-stepper-down {
      position: relative;
      top: 2px;
      height: 4px
    }

    .clockify-integration-popup div.custom-field-ta {
      box-sizing: border-box;
      padding: 0px !important;
      border: 0px solid #c6d2d9 !important;
      border-image-width: 0 !important;
      margin: 8px 0px 0px 0px !important;
      height: 40px !important
    }

    .clockify-integration-popup div.custom-field-ta-disabled {
      box-sizing: border-box;
      padding: 0px !important;
      border-image-width: 0 !important;
      margin: 8px 0px 0px 0px !important;
      height: 45px !important
    }

    .clockify-integration-popup div.custom-field-inner {
      box-sizing: border-box;
      padding: 0px 7px !important;
      display: flex !important;
      align-items: center !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
      border: 0px solid #c6d2d9 !important
    }

    .clockify-integration-popup div.custom-field-inner-disabled {
      box-sizing: border-box;
      padding: 0px 7px !important;
      display: flex !important;
      align-items: center !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important
    }

    .clockify-integration-popup div.custom-field-inner-checkbox {
      box-sizing: border-box;
      padding: 0px !important;
      display: flex !important;
      align-items: center !important;
      background-color: #eff4f7 !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
      border: 0px solid #c6d2d9 !important
    }

    .clockify-integration-popup div.custom-field-inner-checkbox-disabled {
      box-sizing: border-box;
      display: flex !important;
      align-items: center !important;
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
      background-color: #eff4f7 !important
    }

    .clockify-integration-popup div.custom-field-inner-checkbox-disabled .clockify-switch-label {
      color: #9c9c9c !important
    }

    .clockify-integration-popup textarea.custom-field-text {
      box-sizing: border-box;
      width: 100% !important;
      height: 100% !important;
      min-height: 40px !important;
      border: 1px solid #c6d2d9;
      margin: 0 !important;
      line-height: 27px !important;
      resize: none !important;
      font-size: 14px !important;
      outline: none !important;
      color: #333;
      padding: 5px 8px !important;
      word-wrap: break-word !important;
      background-color: #fff;
      border-image-width: 0 !important;
      text-overflow: ellipsis !important
    }

    .clockify-integration-popup textarea.custom-field-text::placeholder {
      color: #999;
      opacity: 1
    }

    .clockify-integration-popup textarea.custom-field-text:focus {
      border: 1px solid #90a4ae !important;
      border-radius: 2px !important
    }

    .clockify-integration-popup textarea.custom-field-text-disabled {
      box-sizing: border-box;
      width: 100% !important;
      height: 45px !important;
      min-height: 45px !important;
      border: 0 !important;
      margin: 0 !important;
      resize: none !important;
      font-size: 14px !important;
      outline: none !important;
      color: #333;
      padding: 5px 10px !important;
      word-wrap: break-word !important;
      border: 1px solid #c6d2d9
    }

    .clockify-integration-popup textarea.custom-field-text-disabled::placeholder {
      color: #9c9c9c !important
    }

    .clockify-integration-popup textarea.custom-field-text.custom-field-required {
      border: 1px solid #f44336 !important
    }

    .clockify-integration-popup input.custom-field-number {
      box-sizing: border-box;
      width: 100% !important;
      height: 100% !important;
      padding: 5px 8px !important;
      margin: 0 !important;
      line-height: 20px !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      border: 1px solid #c6d2d9;
      color: #333;
      background-color: #fff !important
    }

    .clockify-integration-popup input.custom-field-number::placeholder {
      color: #999;
      opacity: 1
    }

    .clockify-integration-popup input.custom-field-number-disabled {
      box-sizing: border-box;
      border: 1px solid #c6d2d9;
      width: 100% !important;
      height: 100% !important;
      padding: 5px 8px !important;
      margin: 0 !important;
      background-color: #fff !important;
      line-height: 20px !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      border: 1px solid #c6d2d9 !important;
      background-color: #eff4f7 !important
    }

    .clockify-integration-popup input.custom-field-number.custom-field-required {
      border: 1px solid #f44336 !important
    }

    .clockify-integration-popup .custom-field-link {
      box-sizing: border-box;
      width: 100% !important
    }

    .clockify-integration-popup .custom-field-link::placeholder {
      color: #999;
      opacity: 1
    }

    .clockify-integration-popup .custom-field-link.custom-field-required {
      border: 1px solid #f44336 !important
    }

    .clockify-integration-popup div.custom-field-link-label {
      box-sizing: border-box;
      padding: 5px 0px !important;
      line-height: 21px !important;
      font-size: 14px !important;
      font-weight: 400px !important;
      margin: 0 !important
    }

    .clockify-integration-popup .cf-container {
      box-sizing: border-box;
      display: block !important;
      position: relative !important;
      padding-left: 35px !important;
      line-height: 21px !important;
      cursor: pointer !important;
      font-size: 14px !important;
      -webkit-user-select: none !important;
      -moz-user-select: none !important;
      -ms-user-select: none !important;
      user-select: none !important;
      margin: 0 !important
    }

    .clockify-integration-popup .cf-container input {
      box-sizing: border-box;
      position: absolute !important;
      opacity: 0 !important;
      cursor: pointer !important
    }

    .clockify-integration-popup .clockify-switch-label {
      box-sizing: border-box;
      color: #333 !important;
      font-size: 14px !important;
      white-space: nowrap !important;
      margin: 0px 0px 0px 10px !important;
      width: 230px;
      overflow: hidden !important;
      text-overflow: ellipsis !important
    }

    .clockify-integration-popup .checkmark {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      height: 21px !important;
      width: 21px !important;
      border: 1px solid gray !important;
      border-radius: 50% !important
    }

    .clockify-integration-popup .cf-container:hover input~.checkmark {
      background-color: #ccc !important
    }

    .clockify-integration-popup .cf-container input:checked~.checkmark {
      background-color: #2196f3 !important
    }

    .clockify-integration-popup .checkmark:after {
      content: "" !important;
      position: absolute !important;
      display: none !important
    }

    .clockify-integration-popup .cf-container input:checked~.checkmark:after {
      display: block !important
    }

    .clockify-integration-popup .cf-container .checkmark:after {
      top: 5px !important;
      left: 6px !important;
      width: 8px !important;
      height: 8px !important;
      border-radius: 50% !important;
      background: #fff !important
    }

    .clockify-integration-popup .clockify-modal {
      box-sizing: border-box !important;
      -webkit-box-sizing: border-box !important;
      font-family: "Roboto", sans-serif !important;
      font-weight: normal !important;
      position: fixed !important;
      z-index: 9999999 !important;
      padding-top: 100px !important;
      left: 0 !important;
      top: 0 !important;
      width: 100% !important;
      height: 100% !important;
      overflow: auto !important;
      background-color: #000 !important;
      background-color: rgba(0, 0, 0, .4) !important
    }

    .clockify-integration-popup .clockify-modal-content {
      box-sizing: border-box;
      background-color: #fefefe;
      margin: auto !important;
      border-radius: 2px;
      width: 84% !important
    }

    .clockify-integration-popup .cl-close {
      background-color: #fff;
      border: 0 !important
    }

    .clockify-integration-popup .clockify-close:hover {
      opacity: .75
    }

    .clockify-integration-popup .cl-form-control {
      margin: 0 !important
    }

    .clockify-integration-popup input.clockify-link-input {
      box-sizing: border-box;
      display: block !important;
      flex: 1;
      margin: 0 !important;
      height: 40px !important;
      padding: 7px !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 21px !important;
      color: #333;
      background-color: #fff !important;
      background-clip: padding-box !important;
      border: 1px solid #c6d2d9;
      border-radius: 0px !important;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
      box-shadow: none !important
    }

    .clockify-integration-popup input.clockify-link-input::placeholder {
      color: #999;
      opacity: 1
    }

    .clockify-integration-popup input.clockify-link-input-disabled {
      box-sizing: border-box;
      display: block !important;
      flex: 1;
      margin: 0 !important;
      height: 40px !important;
      padding: 7px !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 21px !important;
      color: #333 !important;
      background-clip: padding-box !important;
      border-radius: 0px !important;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
      box-shadow: none !important;
      border: 1px solid #c6d2d9 !important;
      background-color: #eff4f7 !important
    }

    .clockify-integration-popup input.clockify-link-input:focus {
      border: 1px solid #90a4ae !important;
      border-radius: 2px !important
    }

    .clockify-integration-popup input.clockify-link-input-modal {
      display: block !important;
      flex: 1;
      margin: 0px 0px 0px 10px !important;
      height: 40px !important;
      padding: 7px !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 21px !important;
      color: #333;
      background-color: #fff;
      background-clip: padding-box !important;
      border: 1px solid #c6d2d9 !important;
      border-radius: 2px;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
      box-shadow: none !important
    }

    .clockify-integration-popup input.clockify-link-input-modal:focus {
      border: 1px solid #90a4ae !important;
      border-radius: 2px !important
    }

    .clockify-integration-popup .modal-content {
      box-sizing: border-box;
      position: relative !important;
      display: flex !important;
      flex-direction: column !important;
      width: 100% !important;
      pointer-events: auto !important;
      background-color: #fff !important;
      background-clip: padding-box !important;
      outline: 0 !important
    }

    .clockify-integration-popup .cl-modal-header {
      align-items: center !important;
      display: flex !important;
      justify-content: space-between !important;
      padding: 20px !important;
      border-bottom: 1px solid #c6d2d9;
      border-top-left-radius: 2px !important;
      border-top-right-radius: 2px !important
    }

    .clockify-integration-popup .cl-modal-title {
      font-size: 24px !important;
      font-weight: 100 !important;
      line-height: 36px !important;
      color: #666;
      margin: 0 !important
    }

    .clockify-integration-popup .cl-modal-body {
      position: relative !important;
      display: flex !important;
      padding: 21px !important;
      align-items: center !important
    }

    .clockify-integration-popup .cl-modal-footer {
      display: flex !important;
      align-items: center !important;
      justify-content: flex-end !important;
      padding: 20px !important;
      border-top: 1px solid #c6d2d9;
      border-bottom-right-radius: 2px !important;
      border-bottom-left-radius: 2px !important
    }

    .clockify-integration-popup .clockify-cancel {
      color: #2196f3 !important
    }

    .clockify-integration-popup .clockify-cancel:hover {
      text-decoration: underline
    }

    .clockify-integration-popup .clockify-save {
      color: #fff;
      background-color: #2196f3 !important;
      margin-left: 30px;
      padding: 10px;
      min-width: 67px;
      text-align: center;
      border-radius: 2px
    }

    .clockify-integration-popup .clockify-save--disabled {
      opacity: .65;
      cursor: default !important
    }

    .clockify-integration-popup a.clockify-cancel,
    .clockify-integration-popup a.clockify-save {
      cursor: pointer
    }

    .clockify-integration-popup .checkmark {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      height: 21px !important;
      width: 21px !important;
      border: 1px solid gray !important;
      border-radius: 50% !important
    }

    .clockify-integration-popup .cf-container:hover input~.checkmark {
      background-color: #ccc !important
    }

    .clockify-integration-popup .cf-container input:checked~.checkmark {
      background-color: #2196f3 !important
    }

    .clockify-integration-popup .checkmark:after {
      content: "" !important;
      position: absolute !important;
      display: none !important
    }

    .clockify-integration-popup .cf-container input:checked~.checkmark:after {
      display: block !important
    }

    .clockify-integration-popup .cf-container .checkmark:after {
      top: 6px !important;
      left: 7px !important;
      width: 8px !important;
      height: 8px !important;
      border-radius: 50% !important;
      background: #fff !important
    }

    .clockify-integration-popup .required-fields {
      text-align: center
    }

    .clockify-integration-popup .required-fields button {
      width: 105px;
      height: 45px;
      background-color: #f44336;
      color: #fff;
      border: 1px solid #f44336;
      text-transform: uppercase;
      border-radius: 2px;
      cursor: pointer
    }

    .clockify-integration-popup .required-fields span {
      display: block;
      margin: 50px;
      font-size: 17px
    }

    .clockify-integration-popup .toaster__container {
      position: fixed;
      width: calc(100% - 25px);
      left: 15px;
      bottom: 15px;
      z-index: 1112
    }

    .clockify-integration-popup .toaster__message--container_success,
    .clockify-integration-popup .toaster__message--container_info,
    .clockify-integration-popup .toaster__message--container_error {
      padding: 15px;
      margin-top: 5px;
      box-sizing: border-box;
      color: #fff;
      border-radius: 2px;
      font-size: 14px;
      line-height: 16px;
      position: relative;
      bottom: 15px;
      left: 0
    }

    .clockify-integration-popup .toaster__message--container_success {
      background-color: #8bc34a
    }

    .clockify-integration-popup .toaster__message--container_error {
      background-color: #f44336
    }

    .clockify-integration-popup .toaster__message--container_info {
      background-color: #ff9800
    }

    @keyframes toaster-fadein {
      from {
        bottom: 0;
        opacity: 0
      }

      to {
        bottom: 15px;
        opacity: 1
      }
    }

    @keyframes toaster-fadeout {
      from {
        left: 0;
        opacity: 1
      }

      to {
        left: 100%;
        opacity: 0
      }
    }

    @-webkit-keyframes rotation {
      from {
        -webkit-transform: rotate(0deg)
      }

      to {
        -webkit-transform: rotate(359deg)
      }
    }

    .clockify-integration-popup html {
      min-height: 600px
    }

    .clockify-integration-popup body {
      background-color: #eff4f7;
      margin: 0px;
      overflow-y: overlay;
      overflow-x: hidden;
      user-select: none;
      font-size: 12px
    }

    .clockify-integration-popup * {
      font-family: "Roboto", Arial, sans-serif !important;
      box-sizing: revert
    }

    .clockify-integration-popup p {
      margin-top: revert
    }

    .clockify-integration-popup button {
      text-transform: uppercase
    }

    .clockify-integration-popup hr {
      padding-top: 0px;
      margin: 0px;
      border-top: 0px;
      border-bottom: 1px dotted #cacaca
    }

    .clockify-integration-popup .home_page {
      padding-top: 134px
    }

    .clockify-integration-popup .home_page .clockify-subscription-expired-overlay {
      position: fixed;
      top: 50px;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(198, 210, 217, .2);
      z-index: 1110
    }

    .clockify-integration-popup .home_page .clockify-subscription-expired-message {
      background: #ffeacc;
      color: #854f00;
      font-size: 14px;
      border: 1px solid #ffe2b8;
      border-radius: 8px;
      width: 320px;
      margin: auto
    }

    .clockify-integration-popup .home_page .clockify-subscription-expired-message img {
      float: left;
      padding: 7px 8px
    }

    .clockify-integration-popup .home_page .clockify-subscription-expired-message p {
      margin-top: 5px;
      margin-bottom: 5px;
      padding-left: 37px
    }

    .clockify-integration-popup .modalBackground {
      display: none;
      position: fixed;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, .8);
      left: 0;
      top: 0;
      justify-content: center;
      align-items: center;
      z-index: 1040
    }

    .clockify-integration-popup .modalBackground.show {
      display: flex
    }

    .clockify-integration-popup .dot {
      padding: 2px;
      border-radius: 50%;
      display: inline-block;
      width: 2px;
      height: 2px
    }

    .clockify-integration-popup .ptr-element {
      height: 0
    }

    .clockify-integration-popup ::-webkit-scrollbar {
      width: 5px
    }

    .clockify-integration-popup ::-webkit-scrollbar-thumb {
      background: #03a9f4
    }

    .clockify-integration-popup .invisible {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2
    }

    .clockify-integration-popup .invisible-menu {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100vh;
      z-index: 1050
    }

    .clockify-integration-popup .loading-gif {
      position: relative;
      top: 110px;
      text-align: center
    }

    .clockify-integration-popup .loading-gif img {
      content: url(assets/images/loading.gif)
    }

    .clockify-integration-popup .loading-gif-before-load {
      content: url(assets/images/loading.gif);
      position: relative;
      margin-left: auto;
      margin-right: auto;
      top: 110px
    }

    .clockify-integration-popup .loading-entries-gif {
      padding-bottom: 20px;
      text-align: center
    }

    .clockify-integration-popup .loading-entries-gif img {
      content: url(assets/images/loading.gif)
    }

    .clockify-integration-popup .disabled {
      display: none
    }

    .clockify-integration-popup .pull-loading {
      display: inline-block;
      position: relative;
      top: 35px;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: -1
    }

    .clockify-integration-popup .pull-loading-entries {
      display: inline-block;
      position: relative;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: -1
    }

    .clockify-integration-popup .pull-loading-img1 {
      content: url(assets/images/circle_1.svg);
      width: 50px;
      height: 50px;
      position: relative;
      left: 0;
      top: 0
    }

    .clockify-integration-popup .pull-loading-img2 {
      content: url(assets/images/circle_2.svg);
      width: 4px;
      height: 50px;
      position: absolute;
      left: 23px;
      top: 1px;
      -webkit-animation: rotation 2s infinite linear
    }

    .clockify-integration-popup .time-entry-list {
      position: relative;
      top: 15px
    }

    .clockify-integration-popup .time-entry-list__offline {
      position: relative
    }

    .clockify-integration-popup .expandContainer {
      max-height: 0;
      overflow: hidden;
      transition: max-height .3s linear
    }

    .clockify-integration-popup .checked {
      width: 18px;
      height: 18px;
      background: #03a9f4;
      border: none
    }

    .clockify-integration-popup .checked img {
      margin-top: 3px;
      margin-left: 2px
    }

    .clockify-integration-popup .day-active {
      background: #03a9f4;
      border: 1px solid #03a9f4
    }

    .clockify-integration-popup .day-active span {
      color: #fff
    }

    .clockify-integration-popup .list-disabled {
      opacity: .4;
      cursor: default
    }

    .clockify-integration-popup .ant-picker-time-panel-column::after {
      height: 0 !important
    }

    .clockify-integration-popup .ant-picker-time-panel-column .ant-picker-time-panel-cell-inner {
      width: auto !important
    }

    body.clockify-dark-mode,
    .clockify-dark-mode {
      background-color: #37474f
    }

    body.clockify-dark-mode .logo,
    .clockify-dark-mode .logo {
      width: 98px;
      background-image: url(assets/images/clockify_logo_dark.svg) !important;
      background-size: contain;
      background-repeat: no-repeat
    }

    body.clockify-dark-mode .header_and_timer,
    .clockify-dark-mode .header_and_timer {
      background-color: #37474f
    }

    body.clockify-dark-mode .settings_page__header,
    .clockify-dark-mode .settings_page__header {
      background-color: #37474f
    }

    body.clockify-dark-mode .start-timer,
    .clockify-dark-mode .start-timer {
      background-color: #1d272c;
      border: 1px solid #12191d
    }

    body.clockify-dark-mode .start-timer_description_grey,
    .clockify-dark-mode .start-timer_description_grey {
      color: #90a4ae
    }

    body.clockify-dark-mode .start-timer_description,
    .clockify-dark-mode .start-timer_description {
      color: #e4eaee
    }

    body.clockify-dark-mode .start-timer_description-input,
    .clockify-dark-mode .start-timer_description-input {
      background-color: #1d272c;
      color: #e4eaee
    }

    body.clockify-dark-mode .start-timer_description-input::placeholder,
    body.clockify-dark-mode .start-timer_placeholder,
    .clockify-dark-mode .start-timer_description-input::placeholder,
    .clockify-dark-mode .start-timer_placeholder {
      color: #90a4ae
    }

    body.clockify-dark-mode .dropdown-menu,
    .clockify-dark-mode .dropdown-menu {
      background-color: #12191d;
      box-shadow: none
    }

    body.clockify-dark-mode .dropdown-header,
    body.clockify-dark-mode .dropdown-item,
    body.clockify-dark-mode .dropdown-subitem,
    .clockify-dark-mode .dropdown-header,
    .clockify-dark-mode .dropdown-item,
    .clockify-dark-mode .dropdown-subitem {
      color: #90a4ae
    }

    body.clockify-dark-mode .dropdown-divider,
    .clockify-dark-mode .dropdown-divider {
      border-bottom: 1px dotted #263238
    }

    body.clockify-dark-mode .rectangle,
    .clockify-dark-mode .rectangle {
      background-color: #12191d;
      box-shadow: none
    }

    body.clockify-dark-mode .time-entries-list,
    .clockify-dark-mode .time-entries-list {
      border: 1px solid #12191d
    }

    body.clockify-dark-mode .time-entries-list-time,
    .clockify-dark-mode .time-entries-list-time {
      background-color: #12191d;
      border-bottom: 1px solid #12191d
    }

    body.clockify-dark-mode .time-entries-list-total,
    body.clockify-dark-mode .time-entries-list-day,
    .clockify-dark-mode .time-entries-list-total,
    .clockify-dark-mode .time-entries-list-day {
      color: #90a4ae
    }

    body.clockify-dark-mode .time-entries-list-total-time,
    .clockify-dark-mode .time-entries-list-total-time {
      color: #c6d2d9
    }

    body.clockify-dark-mode .time-entry,
    body.clockify-dark-mode .time-entry-locked,
    .clockify-dark-mode .time-entry,
    .clockify-dark-mode .time-entry-locked {
      background: #1d272c;
      border: 1px solid #12191d
    }

    body.clockify-dark-mode .time-entry--collapsed,
    .clockify-dark-mode .time-entry--collapsed {
      background-color: #36464f
    }

    body.clockify-dark-mode .time-entry-group-number,
    .clockify-dark-mode .time-entry-group-number {
      background-color: #12191d;
      color: #90a4ae
    }

    body.clockify-dark-mode .time-entry-group-number:hover,
    .clockify-dark-mode .time-entry-group-number:hover {
      background-color: #37474f
    }

    body.clockify-dark-mode .week-header,
    .clockify-dark-mode .week-header {
      color: #c6d2d9
    }

    body.clockify-dark-mode .week-header-total-time,
    .clockify-dark-mode .week-header-total-time {
      color: #c6d2d9
    }

    body.clockify-dark-mode .week-header-total-label,
    .clockify-dark-mode .week-header-total-label {
      color: #90a4ae
    }

    body.clockify-dark-mode .description,
    .clockify-dark-mode .description {
      color: #e4eaee
    }

    body.clockify-dark-mode .time-entry__task-name,
    body.clockify-dark-mode .time-entry__client-name,
    body.clockify-dark-mode .time-entry__right-side--duration,
    .clockify-dark-mode .time-entry__task-name,
    .clockify-dark-mode .time-entry__client-name,
    .clockify-dark-mode .time-entry__right-side--duration {
      color: #c6d2d9
    }

    body.clockify-dark-mode .no-description,
    .clockify-dark-mode .no-description {
      color: #90a4ae
    }

    body.clockify-dark-mode .dropdown-item:hover,
    body.clockify-dark-mode .dropdown-subitem:hover,
    .clockify-dark-mode .dropdown-item:hover,
    .clockify-dark-mode .dropdown-subitem:hover {
      background: #263238
    }

    body.clockify-dark-mode .duration,
    body.clockify-dark-mode .description-textarea,
    .clockify-dark-mode .duration,
    .clockify-dark-mode .description-textarea {
      border: 1px solid #12191d;
      background-color: #263238
    }

    body.clockify-dark-mode .ant-time-picker-input,
    .clockify-dark-mode .ant-time-picker-input {
      background-color: #263238;
      color: #c6d2d9
    }

    body.clockify-dark-mode .duration-duration,
    .clockify-dark-mode .duration-duration {
      background-color: #263238;
      color: #c6d2d9
    }

    body.clockify-dark-mode .duration-label,
    .clockify-dark-mode .duration-label {
      color: #90a4ae
    }

    body.clockify-dark-mode .edit-form-description,
    .clockify-dark-mode .edit-form-description {
      background-color: #263238;
      color: #c6d2d9
    }

    body.clockify-dark-mode .edit-form-description::placeholder,
    .clockify-dark-mode .edit-form-description::placeholder {
      color: #90a4ae
    }

    body.clockify-dark-mode textarea.custom-field-text,
    .clockify-dark-mode textarea.custom-field-text {
      color: #c6d2d9;
      background-color: #263238;
      border: 1px solid #12191d
    }

    body.clockify-dark-mode textarea.custom-field-text-disabled,
    .clockify-dark-mode textarea.custom-field-text-disabled {
      border: 1px solid #12191d !important;
      background-color: #36464f !important;
      cursor: not-allowed;
      color: #90a4ae !important
    }

    body.clockify-dark-mode textarea.custom-field-text-disabled::placeholder,
    .clockify-dark-mode textarea.custom-field-text-disabled::placeholder {
      color: #90a4ae !important
    }

    body.clockify-dark-mode textarea.custom-field-text::placeholder,
    .clockify-dark-mode textarea.custom-field-text::placeholder {
      color: #607d8b !important;
      opacity: 1
    }

    body.clockify-dark-mode .clockify-switch-label,
    .clockify-dark-mode .clockify-switch-label {
      color: #8fa2ad !important
    }

    body.clockify-dark-mode div.custom-field-inner-checkbox,
    .clockify-dark-mode div.custom-field-inner-checkbox {
      background-color: #36464f !important
    }

    body.clockify-dark-mode div.custom-field-inner-checkbox-disabled,
    .clockify-dark-mode div.custom-field-inner-checkbox-disabled {
      border: none !important;
      background-color: initial !important
    }

    body.clockify-dark-mode .clockify-link-input-disabled,
    body.clockify-dark-mode .custom-field-number-disabled,
    .clockify-dark-mode .clockify-link-input-disabled,
    .clockify-dark-mode .custom-field-number-disabled {
      border: 1px solid #12191d !important;
      background-color: #36464f !important;
      cursor: not-allowed;
      color: #90a4ae !important
    }

    body.clockify-dark-mode .clockify-link-input-disabled::placeholder,
    body.clockify-dark-mode .custom-field-number-disabled::placeholder,
    .clockify-dark-mode .clockify-link-input-disabled::placeholder,
    .clockify-dark-mode .custom-field-number-disabled::placeholder {
      color: #90a4ae !important
    }

    body.clockify-dark-mode .tag-list-item-row,
    .clockify-dark-mode .tag-list-item-row {
      color: #fff !important
    }

    body.clockify-dark-mode .custom-fields,
    .clockify-dark-mode .custom-fields {
      background-color: #36464f !important
    }

    body.clockify-dark-mode .custom-field,
    .clockify-dark-mode .custom-field {
      background-color: rgba(0, 0, 0, 0) !important
    }

    body.clockify-dark-mode .custom-field input,
    .clockify-dark-mode .custom-field input {
      background-color: #263238 !important;
      color: #c6d2d9 !important;
      border: 1px solid #12191d;
      outline: none !important
    }

    body.clockify-dark-mode .custom-field input::placeholder,
    .clockify-dark-mode .custom-field input::placeholder {
      color: #607d8b !important;
      opacity: 1
    }

    body.clockify-dark-mode .custom-field-disabled,
    .clockify-dark-mode .custom-field-disabled {
      border: none !important;
      background-color: initial !important
    }

    body.clockify-dark-mode .description-textarea-required,
    body.clockify-dark-mode .project-list-button-required,
    body.clockify-dark-mode .tag-list-button-required,
    .clockify-dark-mode .description-textarea-required,
    .clockify-dark-mode .project-list-button-required,
    .clockify-dark-mode .tag-list-button-required {
      background-color: #263238
    }

    body.clockify-dark-mode .project-list-button,
    body.clockify-dark-mode .tag-list-button,
    body.clockify-dark-mode .edit-form-checkbox,
    body.clockify-dark-mode .tag-list-checkbox,
    body.clockify-dark-mode .default-project-checkbox,
    body.clockify-dark-mode .dark-mode__checkbox,
    body.clockify-dark-mode .settings__send-errors__checkbox,
    body.clockify-dark-mode .settings__auto_start_on_browser_start__checkbox,
    body.clockify-dark-mode .settings__auto_stop_on_browser_close__checkbox,
    body.clockify-dark-mode .settings__reminder__section__checkbox,
    body.clockify-dark-mode .settings__context_menu__section__checkbox,
    body.clockify-dark-mode .settings__idle-detection__checkbox,
    body.clockify-dark-mode .settings__stop_timer__section__checkbox,
    body.clockify-dark-mode .pomodoro__checkbox,
    body.clockify-dark-mode .create-project__checkbox,
    .clockify-dark-mode .project-list-button,
    .clockify-dark-mode .tag-list-button,
    .clockify-dark-mode .edit-form-checkbox,
    .clockify-dark-mode .tag-list-checkbox,
    .clockify-dark-mode .default-project-checkbox,
    .clockify-dark-mode .dark-mode__checkbox,
    .clockify-dark-mode .settings__send-errors__checkbox,
    .clockify-dark-mode .settings__auto_start_on_browser_start__checkbox,
    .clockify-dark-mode .settings__auto_stop_on_browser_close__checkbox,
    .clockify-dark-mode .settings__reminder__section__checkbox,
    .clockify-dark-mode .settings__context_menu__section__checkbox,
    .clockify-dark-mode .settings__idle-detection__checkbox,
    .clockify-dark-mode .settings__stop_timer__section__checkbox,
    .clockify-dark-mode .pomodoro__checkbox,
    .clockify-dark-mode .create-project__checkbox {
      background-color: #263238;
      border: 1px solid #12191d;
      flex-shrink: 0
    }

    body.clockify-dark-mode .project-list-button-disabled,
    body.clockify-dark-mode .tag-list-button-disabled,
    body.clockify-dark-mode .edit-form-checkbox-disabled,
    body.clockify-dark-mode .tag-list-checkbox-disabled,
    body.clockify-dark-mode .default-project-checkbox-disabled,
    body.clockify-dark-mode .dark-mode__checkbox-disabled,
    body.clockify-dark-mode .settings__send-errors__checkbox-disabled,
    body.clockify-dark-mode .settings__auto_start_on_browser_start__checkbox-disabled,
    body.clockify-dark-mode .settings__auto_stop_on_browser_close__checkbox-disabled,
    body.clockify-dark-mode .settings__reminder__section__checkbox-disabled,
    body.clockify-dark-mode .settings__context_menu__section__checkbox-disabled,
    body.clockify-dark-mode .settings__idle-detection__checkbox-disabled,
    body.clockify-dark-mode .settings__stop_timer__section__checkbox-disabled,
    body.clockify-dark-mode .pomodoro__checkbox-disabled,
    body.clockify-dark-mode .create-project__checkbox-disabled,
    .clockify-dark-mode .project-list-button-disabled,
    .clockify-dark-mode .tag-list-button-disabled,
    .clockify-dark-mode .edit-form-checkbox-disabled,
    .clockify-dark-mode .tag-list-checkbox-disabled,
    .clockify-dark-mode .default-project-checkbox-disabled,
    .clockify-dark-mode .dark-mode__checkbox-disabled,
    .clockify-dark-mode .settings__send-errors__checkbox-disabled,
    .clockify-dark-mode .settings__auto_start_on_browser_start__checkbox-disabled,
    .clockify-dark-mode .settings__auto_stop_on_browser_close__checkbox-disabled,
    .clockify-dark-mode .settings__reminder__section__checkbox-disabled,
    .clockify-dark-mode .settings__context_menu__section__checkbox-disabled,
    .clockify-dark-mode .settings__idle-detection__checkbox-disabled,
    .clockify-dark-mode .settings__stop_timer__section__checkbox-disabled,
    .clockify-dark-mode .pomodoro__checkbox-disabled,
    .clockify-dark-mode .create-project__checkbox-disabled {
      background-color: initial !important;
      border: 1px solid #12191d
    }

    body.clockify-dark-mode .project-list-button-disabled .tag-list-selected-item,
    body.clockify-dark-mode .project-list-button-disabled .tag-list-add,
    body.clockify-dark-mode .tag-list-button-disabled .tag-list-selected-item,
    body.clockify-dark-mode .tag-list-button-disabled .tag-list-add,
    body.clockify-dark-mode .edit-form-checkbox-disabled .tag-list-selected-item,
    body.clockify-dark-mode .edit-form-checkbox-disabled .tag-list-add,
    body.clockify-dark-mode .tag-list-checkbox-disabled .tag-list-selected-item,
    body.clockify-dark-mode .tag-list-checkbox-disabled .tag-list-add,
    body.clockify-dark-mode .default-project-checkbox-disabled .tag-list-selected-item,
    body.clockify-dark-mode .default-project-checkbox-disabled .tag-list-add,
    body.clockify-dark-mode .dark-mode__checkbox-disabled .tag-list-selected-item,
    body.clockify-dark-mode .dark-mode__checkbox-disabled .tag-list-add,
    body.clockify-dark-mode .settings__send-errors__checkbox-disabled .tag-list-selected-item,
    body.clockify-dark-mode .settings__send-errors__checkbox-disabled .tag-list-add,
    body.clockify-dark-mode .settings__auto_start_on_browser_start__checkbox-disabled .tag-list-selected-item,
    body.clockify-dark-mode .settings__auto_start_on_browser_start__checkbox-disabled .tag-list-add,
    body.clockify-dark-mode .settings__auto_stop_on_browser_close__checkbox-disabled .tag-list-selected-item,
    body.clockify-dark-mode .settings__auto_stop_on_browser_close__checkbox-disabled .tag-list-add,
    body.clockify-dark-mode .settings__reminder__section__checkbox-disabled .tag-list-selected-item,
    body.clockify-dark-mode .settings__reminder__section__checkbox-disabled .tag-list-add,
    body.clockify-dark-mode .settings__context_menu__section__checkbox-disabled .tag-list-selected-item,
    body.clockify-dark-mode .settings__context_menu__section__checkbox-disabled .tag-list-add,
    body.clockify-dark-mode .settings__idle-detection__checkbox-disabled .tag-list-selected-item,
    body.clockify-dark-mode .settings__idle-detection__checkbox-disabled .tag-list-add,
    body.clockify-dark-mode .settings__stop_timer__section__checkbox-disabled .tag-list-selected-item,
    body.clockify-dark-mode .settings__stop_timer__section__checkbox-disabled .tag-list-add,
    body.clockify-dark-mode .pomodoro__checkbox-disabled .tag-list-selected-item,
    body.clockify-dark-mode .pomodoro__checkbox-disabled .tag-list-add,
    body.clockify-dark-mode .create-project__checkbox-disabled .tag-list-selected-item,
    body.clockify-dark-mode .create-project__checkbox-disabled .tag-list-add,
    .clockify-dark-mode .project-list-button-disabled .tag-list-selected-item,
    .clockify-dark-mode .project-list-button-disabled .tag-list-add,
    .clockify-dark-mode .tag-list-button-disabled .tag-list-selected-item,
    .clockify-dark-mode .tag-list-button-disabled .tag-list-add,
    .clockify-dark-mode .edit-form-checkbox-disabled .tag-list-selected-item,
    .clockify-dark-mode .edit-form-checkbox-disabled .tag-list-add,
    .clockify-dark-mode .tag-list-checkbox-disabled .tag-list-selected-item,
    .clockify-dark-mode .tag-list-checkbox-disabled .tag-list-add,
    .clockify-dark-mode .default-project-checkbox-disabled .tag-list-selected-item,
    .clockify-dark-mode .default-project-checkbox-disabled .tag-list-add,
    .clockify-dark-mode .dark-mode__checkbox-disabled .tag-list-selected-item,
    .clockify-dark-mode .dark-mode__checkbox-disabled .tag-list-add,
    .clockify-dark-mode .settings__send-errors__checkbox-disabled .tag-list-selected-item,
    .clockify-dark-mode .settings__send-errors__checkbox-disabled .tag-list-add,
    .clockify-dark-mode .settings__auto_start_on_browser_start__checkbox-disabled .tag-list-selected-item,
    .clockify-dark-mode .settings__auto_start_on_browser_start__checkbox-disabled .tag-list-add,
    .clockify-dark-mode .settings__auto_stop_on_browser_close__checkbox-disabled .tag-list-selected-item,
    .clockify-dark-mode .settings__auto_stop_on_browser_close__checkbox-disabled .tag-list-add,
    .clockify-dark-mode .settings__reminder__section__checkbox-disabled .tag-list-selected-item,
    .clockify-dark-mode .settings__reminder__section__checkbox-disabled .tag-list-add,
    .clockify-dark-mode .settings__context_menu__section__checkbox-disabled .tag-list-selected-item,
    .clockify-dark-mode .settings__context_menu__section__checkbox-disabled .tag-list-add,
    .clockify-dark-mode .settings__idle-detection__checkbox-disabled .tag-list-selected-item,
    .clockify-dark-mode .settings__idle-detection__checkbox-disabled .tag-list-add,
    .clockify-dark-mode .settings__stop_timer__section__checkbox-disabled .tag-list-selected-item,
    .clockify-dark-mode .settings__stop_timer__section__checkbox-disabled .tag-list-add,
    .clockify-dark-mode .pomodoro__checkbox-disabled .tag-list-selected-item,
    .clockify-dark-mode .pomodoro__checkbox-disabled .tag-list-add,
    .clockify-dark-mode .create-project__checkbox-disabled .tag-list-selected-item,
    .clockify-dark-mode .create-project__checkbox-disabled .tag-list-add {
      color: #90a4ae
    }

    body.clockify-dark-mode .tag-list-add,
    body.clockify-dark-mode .edit-form-billable,
    .clockify-dark-mode .tag-list-add,
    .clockify-dark-mode .edit-form-billable {
      color: #c6d2d9 !important
    }

    body.clockify-dark-mode .checked,
    .clockify-dark-mode .checked {
      width: 18px;
      height: 18px;
      background: #03a9f4;
      border: none
    }

    body.clockify-dark-mode .checked img,
    .clockify-dark-mode .checked img {
      margin-top: 3px;
      margin-left: 2px
    }

    body.clockify-dark-mode .delete-entry-confirmation-dialog,
    .clockify-dark-mode .delete-entry-confirmation-dialog {
      background-color: #263238
    }

    body.clockify-dark-mode .delete-entry-confirmation-dialog-open,
    .clockify-dark-mode .delete-entry-confirmation-dialog-open {
      background-color: rgba(0, 0, 0, .8)
    }

    body.clockify-dark-mode .delete-entry-confirmation-dialog__question,
    .clockify-dark-mode .delete-entry-confirmation-dialog__question {
      color: #90a4ae
    }

    body.clockify-dark-mode .project-list-dropdown,
    body.clockify-dark-mode .tag-list-dropdown,
    .clockify-dark-mode .project-list-dropdown,
    .clockify-dark-mode .tag-list-dropdown {
      background: #12191d;
      box-shadow: none
    }

    body.clockify-dark-mode .project-list-input,
    body.clockify-dark-mode .tag-list-input,
    .clockify-dark-mode .project-list-input,
    .clockify-dark-mode .tag-list-input {
      background: #12191d !important
    }

    body.clockify-dark-mode .project-list-input input,
    body.clockify-dark-mode .tag-list-input input,
    .clockify-dark-mode .project-list-input input,
    .clockify-dark-mode .tag-list-input input {
      background: #263238 !important;
      border: 1px solid #12191d;
      color: #90a4ae
    }

    body.clockify-dark-mode .project-list-input input::placeholder,
    body.clockify-dark-mode .tag-list-input input::placeholder,
    .clockify-dark-mode .project-list-input input::placeholder,
    .clockify-dark-mode .tag-list-input input::placeholder {
      color: #90a4ae
    }

    body.clockify-dark-mode .project-list-client,
    .clockify-dark-mode .project-list-client {
      color: #90a4ae;
      border-top: 1px dotted #263238
    }

    body.clockify-dark-mode .tag-list-item,
    body.clockify-dark-mode .tag-list-load,
    .clockify-dark-mode .tag-list-item,
    .clockify-dark-mode .tag-list-load {
      color: #90a4ae
    }

    body.clockify-dark-mode hr,
    .clockify-dark-mode hr {
      border-bottom: 1px dotted #12191d
    }

    body.clockify-dark-mode .user-settings:last-child,
    .clockify-dark-mode .user-settings:last-child {
      color: #90a4ae
    }

    body.clockify-dark-mode .time-entries-list-not-synced,
    .clockify-dark-mode .time-entries-list-not-synced {
      border: 1px solid #12191d;
      border-radius: 2px
    }

    body.clockify-dark-mode .time-entry-not-synced,
    .clockify-dark-mode .time-entry-not-synced {
      background: #263238;
      border-bottom: 1px solid #12191d
    }

    body.clockify-dark-mode .time-entry-not-synced span,
    .clockify-dark-mode .time-entry-not-synced span {
      color: #90a4ae
    }

    body.clockify-dark-mode .user-settings,
    .clockify-dark-mode .user-settings {
      border-top: 1px dotted #12191d;
      border-bottom: 1px dotted #12191d
    }

    body.clockify-dark-mode .user-settings span,
    .clockify-dark-mode .user-settings span {
      color: #90a4ae
    }

    body.clockify-dark-mode .workspace-list-title,
    body.clockify-dark-mode .default-project-title,
    body.clockify-dark-mode .dark-mode__title,
    body.clockify-dark-mode .settings__send-errors__title,
    body.clockify-dark-mode .settings__send-errors__title--shortcut,
    body.clockify-dark-mode .settings__auto_start_on_browser_start__title,
    body.clockify-dark-mode .settings__auto_stop_on_browser_close__title,
    body.clockify-dark-mode .pomodoro__title,
    .clockify-dark-mode .workspace-list-title,
    .clockify-dark-mode .default-project-title,
    .clockify-dark-mode .dark-mode__title,
    .clockify-dark-mode .settings__send-errors__title,
    .clockify-dark-mode .settings__send-errors__title--shortcut,
    .clockify-dark-mode .settings__auto_start_on_browser_start__title,
    .clockify-dark-mode .settings__auto_stop_on_browser_close__title,
    .clockify-dark-mode .pomodoro__title {
      color: #e4eaee
    }

    body.clockify-dark-mode .workspace-list-selection,
    .clockify-dark-mode .workspace-list-selection {
      background: #263238;
      border: 1px solid #12191d
    }

    body.clockify-dark-mode .workspace-list-default,
    .clockify-dark-mode .workspace-list-default {
      color: #90a4ae
    }

    body.clockify-dark-mode .settings__reminder,
    body.clockify-dark-mode .settings__stop_timer,
    .clockify-dark-mode .settings__reminder,
    .clockify-dark-mode .settings__stop_timer {
      background: #12191d
    }

    body.clockify-dark-mode .settings__stop_timer p,
    .clockify-dark-mode .settings__stop_timer p {
      color: #90a4ae
    }

    body.clockify-dark-mode .settings__reminder__week__day,
    .clockify-dark-mode .settings__reminder__week__day {
      background: #263238;
      border: 1px solid #263238
    }

    body.clockify-dark-mode .settings__reminder__week__day span,
    .clockify-dark-mode .settings__reminder__week__day span {
      color: #90a4ae
    }

    body.clockify-dark-mode .day-active,
    .clockify-dark-mode .day-active {
      background: #03a9f4;
      border: 1px solid #03a9f4
    }

    body.clockify-dark-mode .day-active span,
    .clockify-dark-mode .day-active span {
      color: #fff
    }

    body.clockify-dark-mode .settings__reminder__times--from p,
    body.clockify-dark-mode .settings__reminder__times--to p,
    body.clockify-dark-mode .stop_timer__times--picker p,
    .clockify-dark-mode .settings__reminder__times--from p,
    .clockify-dark-mode .settings__reminder__times--to p,
    .clockify-dark-mode .stop_timer__times--picker p {
      color: #90a4ae
    }

    body.clockify-dark-mode .settings__reminder__time_picker .ant-time-picker-input,
    body.clockify-dark-mode .settings__stop_timer__time_picker .ant-time-picker-input,
    .clockify-dark-mode .settings__reminder__time_picker .ant-time-picker-input,
    .clockify-dark-mode .settings__stop_timer__time_picker .ant-time-picker-input {
      border: 1px solid #12191d
    }

    body.clockify-dark-mode .settings__reminder__times--minutes_since_last_entry input,
    .clockify-dark-mode .settings__reminder__times--minutes_since_last_entry input {
      background: #263238;
      border: 1px solid #12191d;
      color: #e4eaee
    }

    body.clockify-dark-mode .settings__reminder__times--minutes_since_last_entry p,
    .clockify-dark-mode .settings__reminder__times--minutes_since_last_entry p {
      color: #90a4ae
    }

    body.clockify-dark-mode .settings__idle-detection__box,
    .clockify-dark-mode .settings__idle-detection__box {
      background-color: #12191d
    }

    body.clockify-dark-mode .settings__idle-detection__box__content p,
    .clockify-dark-mode .settings__idle-detection__box__content p {
      color: #90a4ae
    }

    body.clockify-dark-mode .settings__idle-detection__box__content input,
    .clockify-dark-mode .settings__idle-detection__box__content input {
      background: #263238;
      border: 1px solid #12191d;
      color: #e4eaee
    }

    body.clockify-dark-mode .pomodoro__content,
    .clockify-dark-mode .pomodoro__content {
      background: #12191d
    }

    body.clockify-dark-mode .pomodoro__border,
    .clockify-dark-mode .pomodoro__border {
      border-bottom: 1px solid #263238
    }

    body.clockify-dark-mode .pomodoro__switch,
    .clockify-dark-mode .pomodoro__switch {
      background-color: #263238;
      border: 1px solid #12191d
    }

    body.clockify-dark-mode .pomodoro__switch::after,
    .clockify-dark-mode .pomodoro__switch::after {
      background-color: #90a4ae
    }

    body.clockify-dark-mode .ant-switch-checked,
    .clockify-dark-mode .ant-switch-checked {
      background-color: #03a9f4
    }

    body.clockify-dark-mode .ant-switch-checked::after,
    .clockify-dark-mode .ant-switch-checked::after {
      background-color: #fff
    }

    body.clockify-dark-mode .pomodoro__box__content p,
    .clockify-dark-mode .pomodoro__box__content p {
      color: #90a4ae
    }

    body.clockify-dark-mode .pomodoro__box__content input,
    .clockify-dark-mode .pomodoro__box__content input {
      background: #263238;
      border: 1px solid #12191d;
      color: #e4eaee
    }

    body.clockify-dark-mode .default-project__project-list,
    .clockify-dark-mode .default-project__project-list {
      background: #12191d
    }

    body.clockify-dark-mode .project-list-arrow,
    body.clockify-dark-mode .tag-list-arrow,
    .clockify-dark-mode .project-list-arrow,
    .clockify-dark-mode .tag-list-arrow {
      width: 10px;
      height: 5px;
      content: url(assets/images/arrow-dark-mode.png)
    }

    body.clockify-dark-mode .project-list-arrow-up,
    body.clockify-dark-mode .tag-list-arrow-up,
    .clockify-dark-mode .project-list-arrow-up,
    .clockify-dark-mode .tag-list-arrow-up {
      width: 10px;
      height: 5px;
      content: url(assets/images/arrow-dark-mode-up.png)
    }

    body.clockify-dark-mode .dropdown-item:hover,
    body.clockify-dark-mode .dropdown-subitem:hover,
    .clockify-dark-mode .dropdown-item:hover,
    .clockify-dark-mode .dropdown-subitem:hover {
      background: #263238
    }

    body.clockify-dark-mode .workspace-list-item:hover,
    .clockify-dark-mode .workspace-list-item:hover {
      background-color: #263238
    }

    body.clockify-dark-mode .workspace-list-dropdown,
    .clockify-dark-mode .workspace-list-dropdown {
      background: #12191d;
      box-shadow: 0 5px 7px #000
    }

    body.clockify-dark-mode .workspace-list-item__name,
    .clockify-dark-mode .workspace-list-item__name {
      color: #90a4ae
    }

    body.clockify-dark-mode .duration-divider,
    .clockify-dark-mode .duration-divider {
      border-left: 1px dotted #12191d;
      background-color: #12191d
    }

    body.clockify-dark-mode ul.project-item:hover,
    .clockify-dark-mode ul.project-item:hover {
      background-color: #263238
    }

    body.clockify-dark-mode li.project-item-name,
    .clockify-dark-mode li.project-item-name {
      color: #fff
    }

    body.clockify-dark-mode .project-list-name,
    .clockify-dark-mode .project-list-name {
      color: #90a4ae
    }

    body.clockify-dark-mode .project-name,
    .clockify-dark-mode .project-name {
      color: #90a4ae
    }

    body.clockify-dark-mode .task-item,
    .clockify-dark-mode .task-item {
      color: #90a4ae;
      background-color: #263238
    }

    body.clockify-dark-mode .task-item:hover,
    .clockify-dark-mode .task-item:hover {
      background-color: #37474f
    }

    body.clockify-dark-mode .tag-list-item,
    .clockify-dark-mode .tag-list-item {
      color: #90a4ae
    }

    body.clockify-dark-mode .tag-list-item-row:hover,
    .clockify-dark-mode .tag-list-item-row:hover {
      background-color: #263238
    }

    body.clockify-dark-mode .tag-list-selected-item,
    .clockify-dark-mode .tag-list-selected-item {
      color: #c6d2d9
    }

    body.clockify-dark-mode .projects-list__create-task,
    .clockify-dark-mode .projects-list__create-task {
      background-color: #263238
    }

    body.clockify-dark-mode .projects-list__create-project,
    body.clockify-dark-mode .tag-list__create-tag,
    body.clockify-dark-mode .client-list__create-client,
    body.clockify-dark-mode .client-list-dropdown,
    .clockify-dark-mode .projects-list__create-project,
    .clockify-dark-mode .tag-list__create-tag,
    .clockify-dark-mode .client-list__create-client,
    .clockify-dark-mode .client-list-dropdown {
      background-color: #12191d;
      box-shadow: none
    }

    body.clockify-dark-mode .create-project,
    body.clockify-dark-mode .create-task,
    .clockify-dark-mode .create-project,
    .clockify-dark-mode .create-task {
      background-color: #37474f
    }

    body.clockify-dark-mode .create-project__title,
    body.clockify-dark-mode .create-task__title,
    .clockify-dark-mode .create-project__title,
    .clockify-dark-mode .create-task__title {
      color: #c6d2d9
    }

    body.clockify-dark-mode .create-project__project-name,
    body.clockify-dark-mode .create-task__task-name,
    .clockify-dark-mode .create-project__project-name,
    .clockify-dark-mode .create-task__task-name {
      border: 1px solid #12191d;
      background-color: #263238;
      color: #c6d2d9
    }

    body.clockify-dark-mode .create-project__project-name::placeholder,
    body.clockify-dark-mode .create-task__task-name::placeholder,
    .clockify-dark-mode .create-project__project-name::placeholder,
    .clockify-dark-mode .create-task__task-name::placeholder {
      color: #90a4ae
    }

    body.clockify-dark-mode .create-project__project-name:focus,
    body.clockify-dark-mode .create-task__task-name:focus,
    .clockify-dark-mode .create-project__project-name:focus,
    .clockify-dark-mode .create-task__task-name:focus {
      outline: none;
      border: 2px solid #0f3beb;
      border-radius: 5px
    }

    body.clockify-dark-mode .color-picker__title,
    body.clockify-dark-mode .create-project__billable-title,
    body.clockify-dark-mode .create-project__public-title,
    .clockify-dark-mode .color-picker__title,
    .clockify-dark-mode .create-project__billable-title,
    .clockify-dark-mode .create-project__public-title {
      color: #90a4ae
    }

    body.clockify-dark-mode .create-project__divider,
    .clockify-dark-mode .create-project__divider {
      border-bottom: 1px dotted #12191d
    }

    body.clockify-dark-mode .client-list-button,
    .clockify-dark-mode .client-list-button {
      border: 1px solid #12191d;
      background-color: #263238
    }

    body.clockify-dark-mode .client-list-name,
    .clockify-dark-mode .client-list-name {
      color: #90a4ae
    }

    body.clockify-dark-mode .client-list-arrow,
    .clockify-dark-mode .client-list-arrow {
      content: url(assets/images/arrow-dark-mode.png)
    }

    body.clockify-dark-mode .client-list-input,
    .clockify-dark-mode .client-list-input {
      background: #12191d
    }

    body.clockify-dark-mode .client-list-filter,
    .clockify-dark-mode .client-list-filter {
      background-color: #263238;
      border: none
    }

    body.clockify-dark-mode .client-list-filter::placeholder,
    .clockify-dark-mode .client-list-filter::placeholder {
      color: #c6d2d9
    }

    body.clockify-dark-mode .client-list-client,
    .clockify-dark-mode .client-list-client {
      color: #90a4ae
    }

    body.clockify-dark-mode .client-list__create-form,
    .clockify-dark-mode .client-list__create-form {
      background-color: #37474f
    }

    body.clockify-dark-mode .client-list__create-form--open,
    .clockify-dark-mode .client-list__create-form--open {
      background-color: rgba(0, 0, 0, .5)
    }

    body.clockify-dark-mode .client-list__create-form--divider,
    .clockify-dark-mode .client-list__create-form--divider {
      border-bottom: 1px dotted #12191d
    }

    body.clockify-dark-mode .client-list__create-form--title,
    .clockify-dark-mode .client-list__create-form--title {
      color: #c6d2d9
    }

    body.clockify-dark-mode .client-list__create-form--client-name,
    .clockify-dark-mode .client-list__create-form--client-name {
      border: 1px solid #12191d;
      background-color: #263238;
      color: #c6d2d9
    }

    body.clockify-dark-mode .client-list__create-form--client-name::placeholder,
    .clockify-dark-mode .client-list__create-form--client-name::placeholder {
      color: #90a4ae
    }

    body.clockify-dark-mode .tag-list--not_tags,
    .clockify-dark-mode .tag-list--not_tags {
      color: #8c9fa9
    }

    body.clockify-dark-mode .tag-list__create-form,
    body.clockify-dark-mode .poppup-modal,
    .clockify-dark-mode .tag-list__create-form,
    .clockify-dark-mode .poppup-modal {
      background-color: #37474f
    }

    body.clockify-dark-mode .tag-list__create-form--open,
    body.clockify-dark-mode .poppup-modal--open,
    .clockify-dark-mode .tag-list__create-form--open,
    .clockify-dark-mode .poppup-modal--open {
      background-color: rgba(0, 0, 0, .5)
    }

    body.clockify-dark-mode .tag-list__create-form--divider,
    body.clockify-dark-mode .poppup-modal--divider,
    .clockify-dark-mode .tag-list__create-form--divider,
    .clockify-dark-mode .poppup-modal--divider {
      border-bottom: 1px dotted #12191d
    }

    body.clockify-dark-mode .tag-list__create-form--title,
    body.clockify-dark-mode .tag-list__create-form p,
    body.clockify-dark-mode .poppup-modal--title,
    body.clockify-dark-mode .poppup-modal p,
    .clockify-dark-mode .tag-list__create-form--title,
    .clockify-dark-mode .tag-list__create-form p,
    .clockify-dark-mode .poppup-modal--title,
    .clockify-dark-mode .poppup-modal p {
      color: #c6d2d9
    }

    body.clockify-dark-mode .tag-list__create-form--tag-name,
    body.clockify-dark-mode .poppup-modal--tag-name,
    .clockify-dark-mode .tag-list__create-form--tag-name,
    .clockify-dark-mode .poppup-modal--tag-name {
      border: 1px solid #12191d;
      background-color: #263238;
      color: #c6d2d9
    }

    body.clockify-dark-mode .tag-list__create-form--tag-name::placeholder,
    body.clockify-dark-mode .poppup-modal--tag-name::placeholder,
    .clockify-dark-mode .tag-list__create-form--tag-name::placeholder,
    .clockify-dark-mode .poppup-modal--tag-name::placeholder {
      color: #90a4ae
    }

    body.clockify-dark-mode .clockify-modal-content,
    .clockify-dark-mode .clockify-modal-content {
      background-color: #37474f;
      border: none;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, .25)
    }

    body.clockify-dark-mode .clockify-modal-content .cl-modal-title,
    body.clockify-dark-mode .clockify-modal-content .custom-field-link-label,
    .clockify-dark-mode .clockify-modal-content .cl-modal-title,
    .clockify-dark-mode .clockify-modal-content .custom-field-link-label {
      color: #c6d2d9
    }

    body.clockify-dark-mode .clockify-modal-content .custom-field-link,
    .clockify-dark-mode .clockify-modal-content .custom-field-link {
      background-color: #263238;
      color: #c6d2d9
    }

    body.clockify-dark-mode .clockify-modal-content .cl-close,
    .clockify-dark-mode .clockify-modal-content .cl-close {
      background: none
    }

    body.clockify-dark-mode .clockify-modal-content .cl-close .clockify-close,
    .clockify-dark-mode .clockify-modal-content .cl-close .clockify-close {
      background: none
    }

    body.clockify-dark-mode .clockify-modal-content .cl-modal-header,
    .clockify-dark-mode .clockify-modal-content .cl-modal-header {
      border-bottom: 1px dotted #12191d
    }

    body.clockify-dark-mode .clockify-modal-content .cl-modal-footer,
    .clockify-dark-mode .clockify-modal-content .cl-modal-footer {
      border-top: 1px dotted #12191d
    }

    body.clockify-dark-mode .tag-list-item--comma,
    .clockify-dark-mode .tag-list-item--comma {
      color: #fff
    }

    body.clockify-dark-mode .app-version,
    .clockify-dark-mode .app-version {
      color: #c6d2d9
    }

    body.clockify-dark-mode .clockify-error,
    .clockify-dark-mode .clockify-error {
      color: red;
      font-size: 14px
    }

    body.clockify-dark-mode .loading-entries,
    .clockify-dark-mode .loading-entries {
      color: #c6d2d9
    }

    body.clockify-dark-mode .autocomplete-dropdown-item,
    .clockify-dark-mode .autocomplete-dropdown-item {
      color: #90a4ae;
      background-color: #1d272c
    }

    body.clockify-dark-mode .autocomplete-dropdown-item:hover,
    .clockify-dark-mode .autocomplete-dropdown-item:hover {
      background-color: #263238
    }

    body.clockify-dark-mode .autocomplete-dropdown-item__tag,
    .clockify-dark-mode .autocomplete-dropdown-item__tag {
      color: #90a4ae;
      background: #12191d
    }

    body.clockify-dark-mode .autocomplete-dropdown-item__tag:hover,
    .clockify-dark-mode .autocomplete-dropdown-item__tag:hover {
      background: #37474f
    }

    body.clockify-dark-mode .react-autocomplete>div,
    .clockify-dark-mode .react-autocomplete>div {
      background: #1d272c !important
    }

    body.clockify-dark-mode .edit-form__break-label,
    .clockify-dark-mode .edit-form__break-label {
      background: #263238;
      color: #c6d2d9
    }

    body.clockify-dark-mode.clockify-integration-popup,
    .clockify-dark-mode.clockify-integration-popup {
      color: #c6d2d9
    }

    body.clockify-dark-mode div.custom-field-ta,
    .clockify-dark-mode div.custom-field-ta {
      background-color: #37474f !important
    }

    body.clockify-dark-mode .clockify-manual-entry-header-text,
    .clockify-dark-mode .clockify-manual-entry-header-text {
      color: #f4f4f4
    }

    body.clockify-dark-mode .notification .notification-title,
    .clockify-dark-mode .notification .notification-title {
      color: #c6d2d9 !important
    }

    body.clockify-dark-mode .notification .notification-message,
    .clockify-dark-mode .notification .notification-message {
      color: #c6d2d9 !important
    }

    body.clockify-dark-mode .notification:hover,
    .clockify-dark-mode .notification:hover {
      background-color: #263238 !important
    }

    style {
      display: none
    }
  </style>
  <style>
    @charset "UTF-8";

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before {
      border-color: #ccc;
      border-style: solid;
      border-width: 3px 3px 0 0;
      content: "";
      display: block;
      height: 9px;
      position: absolute;
      top: 6px;
      width: 9px;
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle,
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
      margin-left: -4px;
      position: absolute;
      width: 0;
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
      box-sizing: content-box;
      position: absolute;
      border: 8px solid transparent;
      height: 0;
      width: 1px;
      content: "";
      z-index: -1;
      border-width: 8px;
      left: -8px;
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
      border-bottom-color: #aeaeae;
    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
      top: 0;
      margin-top: -8px;
    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
      border-top: none;
      border-bottom-color: #f0f0f0;
    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
      top: 0;
    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
      top: -1px;
      border-bottom-color: #aeaeae;
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
      bottom: 0;
      margin-bottom: -8px;
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
      border-bottom: none;
      border-top-color: #fff;
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
      bottom: 0;
    }

    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
      bottom: -1px;
      border-top-color: #aeaeae;
    }

    .react-datepicker-wrapper {
      display: inline-block;
      padding: 0;
      border: 0;
    }

    .react-datepicker {
      font-family: "Helvetica Neue", helvetica, arial, sans-serif;
      font-size: 0.8rem;
      background-color: #fff;
      color: #000;
      border: 1px solid #aeaeae;
      border-radius: 0.3rem;
      display: inline-block;
      position: relative;
    }

    .react-datepicker--time-only .react-datepicker__triangle {
      left: 35px;
    }

    .react-datepicker--time-only .react-datepicker__time-container {
      border-left: 0;
    }

    .react-datepicker--time-only .react-datepicker__time,
    .react-datepicker--time-only .react-datepicker__time-box {
      border-bottom-left-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
    }

    .react-datepicker__triangle {
      position: absolute;
      left: 50px;
    }

    .react-datepicker-popper {
      z-index: 1;
    }

    .react-datepicker-popper[data-placement^=bottom] {
      padding-top: 10px;
    }

    .react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle,
    .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
      left: auto;
      right: 50px;
    }

    .react-datepicker-popper[data-placement^=top] {
      padding-bottom: 10px;
    }

    .react-datepicker-popper[data-placement^=right] {
      padding-left: 8px;
    }

    .react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
      left: auto;
      right: 42px;
    }

    .react-datepicker-popper[data-placement^=left] {
      padding-right: 8px;
    }

    .react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
      left: 42px;
      right: auto;
    }

    .react-datepicker__header {
      text-align: center;
      background-color: #f0f0f0;
      border-bottom: 1px solid #aeaeae;
      border-top-left-radius: 0.3rem;
      padding: 8px 0;
      position: relative;
    }

    .react-datepicker__header--time {
      padding-bottom: 8px;
      padding-left: 5px;
      padding-right: 5px;
    }

    .react-datepicker__header--time:not(.react-datepicker__header--time--only) {
      border-top-left-radius: 0;
    }

    .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
      border-top-right-radius: 0.3rem;
    }

    .react-datepicker__year-dropdown-container--select,
    .react-datepicker__month-dropdown-container--select,
    .react-datepicker__month-year-dropdown-container--select,
    .react-datepicker__year-dropdown-container--scroll,
    .react-datepicker__month-dropdown-container--scroll,
    .react-datepicker__month-year-dropdown-container--scroll {
      display: inline-block;
      margin: 0 15px;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      margin-top: 0;
      color: #000;
      font-weight: bold;
      font-size: 0.944rem;
    }

    .react-datepicker-time__header {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .react-datepicker__navigation {
      align-items: center;
      background: none;
      display: flex;
      justify-content: center;
      text-align: center;
      cursor: pointer;
      position: absolute;
      top: 2px;
      padding: 0;
      border: none;
      z-index: 1;
      height: 32px;
      width: 32px;
      text-indent: -999em;
      overflow: hidden;
    }

    .react-datepicker__navigation--previous {
      left: 2px;
    }

    .react-datepicker__navigation--next {
      right: 2px;
    }

    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 85px;
    }

    .react-datepicker__navigation--years {
      position: relative;
      top: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .react-datepicker__navigation--years-previous {
      top: 4px;
    }

    .react-datepicker__navigation--years-upcoming {
      top: -4px;
    }

    .react-datepicker__navigation:hover *::before {
      border-color: #a6a6a6;
    }

    .react-datepicker__navigation-icon {
      position: relative;
      top: -1px;
      font-size: 20px;
      width: 0;
    }

    .react-datepicker__navigation-icon--next {
      left: -2px;
    }

    .react-datepicker__navigation-icon--next::before {
      transform: rotate(45deg);
      left: -7px;
    }

    .react-datepicker__navigation-icon--previous {
      right: -2px;
    }

    .react-datepicker__navigation-icon--previous::before {
      transform: rotate(225deg);
      right: -7px;
    }

    .react-datepicker__month-container {
      float: left;
    }

    .react-datepicker__year {
      margin: 0.4rem;
      text-align: center;
    }

    .react-datepicker__year-wrapper {
      display: flex;
      flex-wrap: wrap;
      max-width: 180px;
    }

    .react-datepicker__year .react-datepicker__year-text {
      display: inline-block;
      width: 4rem;
      margin: 2px;
    }

    .react-datepicker__month {
      margin: 0.4rem;
      text-align: center;
    }

    .react-datepicker__month .react-datepicker__month-text,
    .react-datepicker__month .react-datepicker__quarter-text {
      display: inline-block;
      width: 4rem;
      margin: 2px;
    }

    .react-datepicker__input-time-container {
      clear: both;
      width: 100%;
      float: left;
      margin: 5px 0 10px 15px;
      text-align: left;
    }

    .react-datepicker__input-time-container .react-datepicker-time__caption {
      display: inline-block;
    }

    .react-datepicker__input-time-container .react-datepicker-time__input-container {
      display: inline-block;
    }

    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
      display: inline-block;
      margin-left: 10px;
    }

    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
      width: auto;
    }

    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
      -moz-appearance: textfield;
    }

    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
      margin-left: 5px;
      display: inline-block;
    }

    .react-datepicker__time-container {
      float: right;
      border-left: 1px solid #aeaeae;
      width: 85px;
    }

    .react-datepicker__time-container--with-today-button {
      display: inline;
      border: 1px solid #aeaeae;
      border-radius: 0.3rem;
      position: absolute;
      right: -87px;
      top: 0;
    }

    .react-datepicker__time-container .react-datepicker__time {
      position: relative;
      background: white;
      border-bottom-right-radius: 0.3rem;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 85px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center;
      border-bottom-right-radius: 0.3rem;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
      list-style: none;
      margin: 0;
      height: calc(195px + 1.7rem / 2);
      overflow-y: scroll;
      padding-right: 0;
      padding-left: 0;
      width: 100%;
      box-sizing: content-box;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
      height: 30px;
      padding: 5px 10px;
      white-space: nowrap;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
      cursor: pointer;
      background-color: #f0f0f0;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
      background-color: #216ba5;
      color: white;
      font-weight: bold;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
      background-color: #216ba5;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
      color: #ccc;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
      cursor: default;
      background-color: transparent;
    }

    .react-datepicker__week-number {
      color: #ccc;
      display: inline-block;
      width: 1.7rem;
      line-height: 1.7rem;
      text-align: center;
      margin: 0.166rem;
    }

    .react-datepicker__week-number.react-datepicker__week-number--clickable {
      cursor: pointer;
    }

    .react-datepicker__week-number.react-datepicker__week-number--clickable:not(.react-datepicker__week-number--selected,
      .react-datepicker__week-number--keyboard-selected):hover {
      border-radius: 0.3rem;
      background-color: #f0f0f0;
    }

    .react-datepicker__week-number--selected {
      border-radius: 0.3rem;
      background-color: #216ba5;
      color: #fff;
    }

    .react-datepicker__week-number--selected:hover {
      background-color: #1d5d90;
    }

    .react-datepicker__week-number--keyboard-selected {
      border-radius: 0.3rem;
      background-color: #2a87d0;
      color: #fff;
    }

    .react-datepicker__week-number--keyboard-selected:hover {
      background-color: #1d5d90;
    }

    .react-datepicker__day-names {
      white-space: nowrap;
      margin-bottom: -8px;
    }

    .react-datepicker__week {
      white-space: nowrap;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: #000;
      display: inline-block;
      width: 1.7rem;
      line-height: 1.7rem;
      text-align: center;
      margin: 0.166rem;
    }

    .react-datepicker__day,
    .react-datepicker__month-text,
    .react-datepicker__quarter-text,
    .react-datepicker__year-text {
      cursor: pointer;
    }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
      border-radius: 0.3rem;
      background-color: #f0f0f0;
    }

    .react-datepicker__day--today,
    .react-datepicker__month-text--today,
    .react-datepicker__quarter-text--today,
    .react-datepicker__year-text--today {
      font-weight: bold;
    }

    .react-datepicker__day--highlighted,
    .react-datepicker__month-text--highlighted,
    .react-datepicker__quarter-text--highlighted,
    .react-datepicker__year-text--highlighted {
      border-radius: 0.3rem;
      background-color: #3dcc4a;
      color: #fff;
    }

    .react-datepicker__day--highlighted:hover,
    .react-datepicker__month-text--highlighted:hover,
    .react-datepicker__quarter-text--highlighted:hover,
    .react-datepicker__year-text--highlighted:hover {
      background-color: #32be3f;
    }

    .react-datepicker__day--highlighted-custom-1,
    .react-datepicker__month-text--highlighted-custom-1,
    .react-datepicker__quarter-text--highlighted-custom-1,
    .react-datepicker__year-text--highlighted-custom-1 {
      color: magenta;
    }

    .react-datepicker__day--highlighted-custom-2,
    .react-datepicker__month-text--highlighted-custom-2,
    .react-datepicker__quarter-text--highlighted-custom-2,
    .react-datepicker__year-text--highlighted-custom-2 {
      color: green;
    }

    .react-datepicker__day--holidays,
    .react-datepicker__month-text--holidays,
    .react-datepicker__quarter-text--holidays,
    .react-datepicker__year-text--holidays {
      position: relative;
      border-radius: 0.3rem;
      background-color: #ff6803;
      color: #fff;
    }

    .react-datepicker__day--holidays .holiday-overlay,
    .react-datepicker__month-text--holidays .holiday-overlay,
    .react-datepicker__quarter-text--holidays .holiday-overlay,
    .react-datepicker__year-text--holidays .holiday-overlay {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      background-color: #333;
      color: #fff;
      padding: 4px;
      border-radius: 4px;
      white-space: nowrap;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.3s ease-in-out;
    }

    .react-datepicker__day--holidays:hover,
    .react-datepicker__month-text--holidays:hover,
    .react-datepicker__quarter-text--holidays:hover,
    .react-datepicker__year-text--holidays:hover {
      background-color: #cf5300;
    }

    .react-datepicker__day--holidays:hover .holiday-overlay,
    .react-datepicker__month-text--holidays:hover .holiday-overlay,
    .react-datepicker__quarter-text--holidays:hover .holiday-overlay,
    .react-datepicker__year-text--holidays:hover .holiday-overlay {
      visibility: visible;
      opacity: 1;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      border-radius: 0.3rem;
      background-color: #216ba5;
      color: #fff;
    }

    .react-datepicker__day--selected:hover,
    .react-datepicker__day--in-selecting-range:hover,
    .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover,
    .react-datepicker__quarter-text--selected:hover,
    .react-datepicker__quarter-text--in-selecting-range:hover,
    .react-datepicker__quarter-text--in-range:hover,
    .react-datepicker__year-text--selected:hover,
    .react-datepicker__year-text--in-selecting-range:hover,
    .react-datepicker__year-text--in-range:hover {
      background-color: #1d5d90;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      border-radius: 0.3rem;
      background-color: #bad9f1;
      color: rgb(0, 0, 0);
    }

    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover,
    .react-datepicker__quarter-text--keyboard-selected:hover,
    .react-datepicker__year-text--keyboard-selected:hover {
      background-color: #1d5d90;
    }

    .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range),
    .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range),
    .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range),
    .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--in-range) {
      background-color: rgba(33, 107, 165, 0.5);
    }

    .react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range),
    .react-datepicker__year--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range),
    .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range),
    .react-datepicker__year--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range),
    .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range),
    .react-datepicker__year--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range),
    .react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range),
    .react-datepicker__year--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__year-text--in-selecting-range) {
      background-color: #f0f0f0;
      color: #000;
    }

    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
      cursor: default;
      color: #ccc;
    }

    .react-datepicker__day--disabled:hover,
    .react-datepicker__month-text--disabled:hover,
    .react-datepicker__quarter-text--disabled:hover,
    .react-datepicker__year-text--disabled:hover {
      background-color: transparent;
    }

    .react-datepicker__input-container {
      position: relative;
      display: inline-block;
      width: 100%;
    }

    .react-datepicker__input-container .react-datepicker__calendar-icon {
      position: absolute;
      padding: 0.5rem;
      box-sizing: content-box;
    }

    .react-datepicker__view-calendar-icon input {
      padding: 6px 10px 5px 25px;
    }

    .react-datepicker__year-read-view,
    .react-datepicker__month-read-view,
    .react-datepicker__month-year-read-view {
      border: 1px solid transparent;
      border-radius: 0.3rem;
      position: relative;
    }

    .react-datepicker__year-read-view:hover,
    .react-datepicker__month-read-view:hover,
    .react-datepicker__month-year-read-view:hover {
      cursor: pointer;
    }

    .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
      border-top-color: #b3b3b3;
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow {
      transform: rotate(135deg);
      right: -16px;
      top: 0;
    }

    .react-datepicker__year-dropdown,
    .react-datepicker__month-dropdown,
    .react-datepicker__month-year-dropdown {
      background-color: #f0f0f0;
      position: absolute;
      width: 50%;
      left: 25%;
      top: 30px;
      z-index: 1;
      text-align: center;
      border-radius: 0.3rem;
      border: 1px solid #aeaeae;
    }

    .react-datepicker__year-dropdown:hover,
    .react-datepicker__month-dropdown:hover,
    .react-datepicker__month-year-dropdown:hover {
      cursor: pointer;
    }

    .react-datepicker__year-dropdown--scrollable,
    .react-datepicker__month-dropdown--scrollable,
    .react-datepicker__month-year-dropdown--scrollable {
      height: 150px;
      overflow-y: scroll;
    }

    .react-datepicker__year-option,
    .react-datepicker__month-option,
    .react-datepicker__month-year-option {
      line-height: 20px;
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .react-datepicker__year-option:first-of-type,
    .react-datepicker__month-option:first-of-type,
    .react-datepicker__month-year-option:first-of-type {
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
    }

    .react-datepicker__year-option:last-of-type,
    .react-datepicker__month-option:last-of-type,
    .react-datepicker__month-year-option:last-of-type {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border-bottom-left-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
    }

    .react-datepicker__year-option:hover,
    .react-datepicker__month-option:hover,
    .react-datepicker__month-year-option:hover {
      background-color: #ccc;
    }

    .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
      border-bottom-color: #b3b3b3;
    }

    .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
      border-top-color: #b3b3b3;
    }

    .react-datepicker__year-option--selected,
    .react-datepicker__month-option--selected,
    .react-datepicker__month-year-option--selected {
      position: absolute;
      left: 15px;
    }

    .react-datepicker__close-icon {
      cursor: pointer;
      background-color: transparent;
      border: 0;
      outline: 0;
      padding: 0 6px 0 0;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      display: table-cell;
      vertical-align: middle;
    }

    .react-datepicker__close-icon::after {
      cursor: pointer;
      background-color: #216ba5;
      color: #fff;
      border-radius: 50%;
      height: 16px;
      width: 16px;
      padding: 2px;
      font-size: 12px;
      line-height: 1;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      content: "×";
    }

    .react-datepicker__close-icon--disabled {
      cursor: default;
    }

    .react-datepicker__close-icon--disabled::after {
      cursor: default;
      background-color: #ccc;
    }

    .react-datepicker__today-button {
      background: #f0f0f0;
      border-top: 1px solid #aeaeae;
      cursor: pointer;
      text-align: center;
      font-weight: bold;
      padding: 5px 0;
      clear: left;
    }

    .react-datepicker__portal {
      position: fixed;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.8);
      left: 0;
      top: 0;
      justify-content: center;
      align-items: center;
      display: flex;
      z-index: 2147483647;
    }

    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
      width: 3rem;
      line-height: 3rem;
    }

    @media (max-width: 400px),
    (max-height: 550px) {

      .react-datepicker__portal .react-datepicker__day-name,
      .react-datepicker__portal .react-datepicker__day,
      .react-datepicker__portal .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
      }
    }

    .react-datepicker__portal .react-datepicker__current-month,
    .react-datepicker__portal .react-datepicker-time__header {
      font-size: 1.44rem;
    }

    .react-datepicker__children-container {
      width: 13.8rem;
      margin: 0.4rem;
      padding-right: 0.2rem;
      padding-left: 0.2rem;
      height: auto;
    }

    .react-datepicker__aria-live {
      position: absolute;
      clip-path: circle(0);
      border: 0;
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      width: 1px;
      white-space: nowrap;
    }

    .react-datepicker__calendar-icon {
      width: 1em;
      height: 1em;
      vertical-align: -0.125em;
    }
  </style>
</head>

<body
  style="margin: 0; padding: 0; width: 100%; word-break: break-word; -webkit-font-smoothing: antialiased; --bg-opacity: 1; background-color: #eceff1; background-color: rgba(236, 239, 241, var(--bg-opacity));">
  <div style="display: none;">Please verify your email address</div>
  <div role="article" aria-roledescription="email" aria-label="Verify Email Address" lang="en">
    <table style="font-family: Montserrat, -apple-system, &#39;Segoe UI&#39;, sans-serif; width: 100%;" width="100%"
      cellpadding="0" cellspacing="0" role="presentation">
      <tbody>
        <tr>
          <td align="center"
            style="--bg-opacity: 1; background-color: #eceff1; background-color: rgba(236, 239, 241, var(--bg-opacity)); font-family: Montserrat, -apple-system, &#39;Segoe UI&#39;, sans-serif;"
            bgcolor="rgba(236, 239, 241, var(--bg-opacity))">
            <table class="sm-w-full" style="font-family: &#39;Montserrat&#39;,Arial,sans-serif; width: 600px;"
              width="600" cellpadding="0" cellspacing="0" role="presentation">
              <tbody>
                <tr>
                  <td class="sm-py-32 sm-px-24"
                    style="font-family: Montserrat, -apple-system, &#39;Segoe UI&#39;, sans-serif; padding: 48px; text-align: center;"
                    align="center">
                    <a href="https://api.salpalaran.com/QRCode/logo.png">
                      <img src="https://api.salpalaran.com/QRCode/logo.png" width="155" alt="PT SAL - Guest Book"
                        style="width: 9em; height: 9em; object-fit: contain; border-radius: 50%; box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); border: 4px solid #000; background-color: #fff; padding: 5px;">
                    </a>
                  </td>
                </tr>
                <tr>
                  <td align="center" class="sm-px-24" style="font-family: &#39;Montserrat&#39;,Arial,sans-serif;">
                    <table style="font-family: &#39;Montserrat&#39;,Arial,sans-serif; width: 100%;" width="100%"
                      cellpadding="0" cellspacing="0" role="presentation">
                      <tbody>
                        <tr>
                          <td class="sm-px-24"
                            style="--bg-opacity: 1; background-color: #ffffff; background-color: rgba(255, 255, 255, var(--bg-opacity)); border-radius: 4px; font-family: Montserrat, -apple-system, &#39;Segoe UI&#39;, sans-serif; font-size: 14px; line-height: 24px; padding: 48px; text-align: left; --text-opacity: 1; color: #626262; color: rgba(98, 98, 98, var(--text-opacity));"
                            bgcolor="rgba(255, 255, 255, var(--bg-opacity))" align="left">
                            <p style="font-weight: 600; font-size: 18px; margin-bottom: 0;">Hey</p>
                            <p
                              style="font-weight: 700; font-size: 20px; margin-top: 0; --text-opacity: 1; color: #ff5850; color: rgba(255, 88, 80, var(--text-opacity));">
                              ${full_name}!</p>
                            <p class="sm-leading-32"
                              style="font-weight: 600; font-size: 20px; margin: 0 0 16px; --text-opacity: 1; color: #263238; color: rgba(38, 50, 56, var(--text-opacity));">
                              Thank you for filling out the guest book 👋
                            </p>
                            <p style="margin: 0 0 24px;">
                              We have sent a QR code to your WhatsApp <h5><code>after <i>Kunjungan Ke Perusahaan</i> has approved your visit</code></h5> Please present the QR code at the entrance upon arrival.
                            </p>
                            <p style="margin: 0 0 24px;">
                              If you did not submit a guest book entry for PT SAL, kindly disregard this message or contact us at
                              <a href="mailto:ptsaranaabadilestari@gmail.com" class="hover-underline"
                                style="--text-opacity: 1; color: #7367f0; color: rgba(115, 103, 240, var(--text-opacity)); text-decoration: none;">ptsaranaabadilestari@gmail.com</a>
                            </p>

                            <table style="font-family: 'Montserrat',Arial,sans-serif;" cellpadding="0" cellspacing="0" role="presentation">
                              <tbody>
                                <tr>
                                  <td
                                    style="mso-padding-alt: 16px 24px; --bg-opacity: 1; background-color: #28a745; background-color: rgba(40, 167, 69, var(--bg-opacity)); border-radius: 4px; font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;"
                                    bgcolor="rgba(40, 167, 69, var(--bg-opacity))">
                                    <a href="https://wa.me/6285321627603"
                                      style="display: block; font-weight: 600; font-size: 14px; line-height: 100%; padding: 16px 24px; --text-opacity: 1; color: #ffffff; color: rgba(255, 255, 255, var(--text-opacity)); text-decoration: none;">
                                      Open Whatsapp →
                                    </a>
                                  </td>
                                  <td width="8" style="font-size: 0; line-height: 0;">&nbsp;</td> <!-- Spacer -->
                                  <td
                                    style="mso-padding-alt: 16px 24px; --bg-opacity: 1; background-color: #6c757d; background-color: rgba(108, 117, 125, var(--bg-opacity)); border-radius: 4px; font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;"
                                    bgcolor="rgba(108, 117, 125, var(--bg-opacity))">
                                    <a href="/planning/create-form-umum"
                                      style="display: block; font-weight: 600; font-size: 14px; line-height: 100%; padding: 16px 24px; --text-opacity: 1; color: #ffffff; color: rgba(255, 255, 255, var(--text-opacity)); text-decoration: none;">
                                      Back
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>


                            </table>
                            <table style="font-family: &#39;Montserrat&#39;,Arial,sans-serif; width: 100%;" width="100%"
                              cellpadding="0" cellspacing="0" role="presentation">
                              <tbody>
                                <tr>
                                  <td
                                    style="font-family: &#39;Montserrat&#39;,Arial,sans-serif; padding-top: 32px; padding-bottom: 32px;">
                                    <div
                                      style="--bg-opacity: 1; background-color: #eceff1; background-color: rgba(236, 239, 241, var(--bg-opacity)); height: 1px; line-height: 1px;">
                                      ‌</div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <p style="margin: 0 0 16px;">
                              Not sure why you received this guest? Please
                              <a href="mailto:ptsaranaabadilestari@gmail.com" class="hover-underline"
                                style="--text-opacity: 1; color: #7367f0; color: rgba(115, 103, 240, var(--text-opacity)); text-decoration: none;">let
                                us know</a>.
                            </p>
                            <p style="margin: 0 0 16px;">Thanks, <br>The PT SAL Guest Relations Team</p>
                          </td>
                        </tr>
                        <tr>
                          <td style="font-family: &#39;Montserrat&#39;,Arial,sans-serif; height: 20px;" height="20">
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="font-family: Montserrat, -apple-system, &#39;Segoe UI&#39;, sans-serif; font-size: 12px; padding-left: 48px; padding-right: 48px; --text-opacity: 1; color: #eceff1; color: rgba(236, 239, 241, var(--text-opacity));">
                            <p align="center" style="cursor: default; margin-bottom: 16px;">
                              <a href="https://www.facebook.com/ptsal"
                                style="--text-opacity: 1; color: #263238; color: rgba(38, 50, 56, var(--text-opacity)); text-decoration: none;">
                                <img
                                  src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg" width="17" alt="Facebook"
                                  style="border: 0; max-width: 100%; line-height: 100%; vertical-align: middle; margin-right: 12px;">
                              </a>
                              <a href="https://www.instagram.com/ptsal"
                                style="--text-opacity: 1; color: #263238; color: rgba(38, 50, 56, var(--text-opacity)); text-decoration: none;">
                                <img
                                  src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png" width="17" alt="Instagram"
                                  style="border: 0; max-width: 100%; line-height: 100%; vertical-align: middle; margin-right: 12px;">
                              </a>
                            </p>

                            <p style="--text-opacity: 1; color: #263238; color: rgba(38, 50, 56, var(--text-opacity));">
                              Use of our service and website is subject to our
                              <a href="https://salpalaran.com/" class="hover-underline"
                                style="--text-opacity: 1; color: #7367f0; color: rgba(115, 103, 240, var(--text-opacity)); text-decoration: none;">Terms
                                of Use</a> and
                              <a href="https://salpalaran.com/" class="hover-underline"
                                style="--text-opacity: 1; color: #7367f0; color: rgba(115, 103, 240, var(--text-opacity)); text-decoration: none;">Privacy
                                Policy</a>.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td style="font-family: &#39;Montserrat&#39;,Arial,sans-serif; height: 16px;" height="16">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>


</body>

</html>`
    }
  },
  created() {
    // Optional: Handle cases where data might be missing
    if (!this.successData.full_name) {
      this.$router.push('/ptsal-protections') // Redirect if required data is missing
    }
  }
}
</script>
